import { Icon } from "components/atoms";
import Button from "components/atoms/Buttons/Button";
import dayjs from "dayjs";

export const SelectedValue = ({
  onEdit,
  onDelete,
  experience,
}) => {
  return (
    <div
      className="flex items-center w-full border border-gray-light px-5"
      style={{ backgroundColor: "#ECECEC" }}
    >
      <div className="flex items-center w-full py-5 gap-5 items-center">
        <p className="flex items-center text-sm cera-medium-font gap-2">
          {dayjs(experience.beginning).format("DD/MM/YYYY")}
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" viewBox="0 0 4 5">
            <path id="Polygone_1" data-name="Polygone 1" d="M2.5,0,5,4H0Z" transform="translate(4) rotate(90)" fill="#0027ff" />
          </svg>
          {experience.isActual ? "Aujourd'hui" : dayjs(experience.ending).format("DD/MM/YYYY")}

        </p>
        <p className="avenir-font text-gray-dark -mt-1">{experience.title}</p>
      </div>
      <div className="flex gap-5">
        <Button
          className="h-[30px] w-[30px] border border-blue-dark rounded-full text-sm text-blue-dark flex justify-center items-center"
          onClick={onEdit}
        >
          <Icon name="edit" />
        </Button>
        <Button
          className="h-[30px] w-[30px] border border-red rounded-full text-sm text-red flex justify-center items-center"
          onClick={onDelete}
        >
          <Icon name="close" />
        </Button>
      </div>
    </div>
  );
};
