import joinClasses from "helpers/joinClasses";
import { Link } from "react-router-dom";

export function TalentCard({ talent, onClick = null, ...props }) {
    if (!talent) return null;
    return (
        <Link to={`/talents/${talent.id}`}>
            <div
                onClick={onClick}
                {...props}
                className={joinClasses("flex flex-col items-center min-w-[240px] sm:min-w-[160px] xl:min-w-[180px] drop-shadow-sm transition-all ",
                    onClick ? "cursor-pointer hover:drop-shadow-xl md:hover:-mt-2" : null
                )}
            >
                <img
                    src={talent.User.avatar}
                    alt="avatar"
                    className="w-full"
                />
                <div className="flex flex-col items-center mt-1">
                    <p className="font-medium text-sm">- {talent.ninebox} -</p>
                    <p className="font-bold text-blue-dark">{talent.User.firstname} {talent.User.lastname}</p>
                    <p className="text-xs text-grey">{talent.job}</p>
                </div>
            </div>
        </Link>
    )
}

