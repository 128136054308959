import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchLogin, fetchUserInfo } from "api/auth";

const initialState = {
  token: localStorage.getItem("::token") || null,
  error: "",
  loading: false,
  user: {
    id: "",
    email: "",
    firstname: "",
    lastname: "",
    Role: "",
    status: "",
  },
};

export const login = createAsyncThunk("user/login", async (user) => {
  const response = await fetchLogin(user);
  return response;
});

export const checkToken = createAsyncThunk("user/checkToken", async () => {
  const token = localStorage.getItem("::token");
  const response = await fetchUserInfo(token);
  return response;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.user = { ...initialState.user };
      localStorage.removeItem("::token");
    },
    setUser: (state, action) => {
      state.error = "";
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem("::token", action.payload.token);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.error = "";
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
        localStorage.setItem("::token", action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });

    builder
      .addCase(checkToken.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { logout, setUser } = userSlice.actions;

export const userSelector = (state) => state.user;

export default userSlice.reducer;
