import joinClasses from "helpers/joinClasses";

export const SubSection = ({
  subSection,
  status = "none",
  onClick = () => { },
}) => {
  return (
    <li
      id={`subSection-${subSection.id}`}
      onClick={onClick}
      className={joinClasses(
        "flex gap-2 items-center cursor-pointer text-sm py-0.5",
        status === "active" && "text-blue-dark",
        status === "done" && "text-gray-darkest",
        status === "none" && "text-gray-darkest"
      )}
    >
      <div
        className={joinClasses(
          "w-[12px] h-[12px] rounded-full border ",
          status === "active" && "bg-blue-dark border-blue-dark",
          status === "done" && "bg-white border-blue-dark",
          status === "none" && "bg-white border-gray-light"
        )}
      />
      {subSection.title}
    </li>
  );
};
