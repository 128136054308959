import { Icon, SquareCard } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useEffect } from "react";
import { Section } from "./Section";

export const Breadcrumb = ({
  title = "",
  data = [],
  className = "",
  activeSection = { id: 3, subId: 2 },
  setActiveSection = () => { },
}) => {
  const getStatusSection = (sectionId, activeSectionId) => {
    if (sectionId === activeSectionId) return "active";
    if (sectionId < activeSectionId) return "done";
    return "none";
  };

  useEffect(() => {
    const section = document.getElementById(`section-${activeSection.id}`);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [activeSection]);

  return (
    <SquareCard
      className={joinClasses(
        "w-full xl:w-[400px] sm:min-w-[400px] shadow-lg px-5 max-h-[674px] lg:px-12 py-5 overflow-auto",
        className
      )}
    >
      <h1 className="avenir-black-font px-2 text-gray-darkest tracking-[3px] text-sm items-center gap-2 uppercase hidden xl:flex">
        <Icon
          name="edit"
          className="text-blue-dark text-lg inline-block -rotate-45"
        />{" "}
        {title}
      </h1>
      <ul className="list-none xl:mt-10 flex xl:flex-col gap-2 md:gap-4 w-full sm:justify-center">
        {data.map((section, index) => (
          <Section
            section={section}
            key={index}
            activeSection={activeSection}
            status={getStatusSection(section.id, activeSection.id)}
            isLast={index === data.length - 1}
            setActiveSection={setActiveSection}
          />
        ))}
      </ul>
    </SquareCard>
  );
};
