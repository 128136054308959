import { Icon } from "components/atoms";
import Button from "components/atoms/Buttons/Button";

export const SelectedValue = ({
  onEdit,
  onDelete,
  project,
}) => {
  return (
    <div
      className="flex items-center w-full border border-gray-light px-5"
      style={{ backgroundColor: "#ECECEC" }}
    >
        <p className="cera-light-font w-full text-gray-dark -mt-1">{project}</p>
      <div className="flex gap-5 py-3">
        <Button
          className="h-[30px] w-[30px] border border-blue-dark rounded-full text-sm text-blue-dark flex justify-center items-center"
          onClick={onEdit}
        >
          <Icon name="edit" />
        </Button>
        <Button
          className="h-[30px] w-[30px] border border-red rounded-full text-sm text-red flex justify-center items-center"
          onClick={onDelete}
        >
          <Icon name="close" />
        </Button>
      </div>
    </div>
  );
};
