import Smarthphone from "components/atoms/Icons/Smarthphone.icon";
import triptychData from "features/Talents/Talent/data/triptych";
import AvenirText from "../../../atoms/Texts/AvenirText";

export function Triptych({talent}) {
    return (
        <div>
            <img alt="img" className="w-full aspect-square" src={talent?.User?.avatar} />
            <div className="flex flex-col gap-6 mt-7">
                    <div className="flex items-center gap-2">
                        <Smarthphone/>
                        <AvenirText weight="black" color="gray-darkest" size="xl" className="uppercase">{talent?.phone}</AvenirText>
                    </div>
                {triptychData.map((item, index) => (
                    <div key={index} className="flex items-center gap-2">
                        {item.icon}
                        <AvenirText weight="black" color="gray-darkest" size="[10px]"className="uppercase">{item.title}</AvenirText>
                    </div>
                ))}
            </div>
        </div>
    )
}