import RoundedButton from "components/atoms/Buttons/RoundedButton";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";
import TitleSection from "../../../_components/Title";
import { ExperienceForm } from "./form";
import { SelectedValue } from "./SelectedValue";
import AddIcon from "components/atoms/Icons/Add.icon";

export const Experiences = ({
  handleChange,
  getValue,
}) => {
  const [showExperienceForm, setShowExperienceForm] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [experiences, setExperiences] = useState([]);

  const handleAddExperience = (experience, isEditing = false) => {
    let joinedExperiences = [];
    if (isEditing) {
      joinedExperiences = experiences.map((exp) => {
        if (exp.id === experience.id) return experience;
        return exp;
      });
    } else {
      joinedExperiences = [...experiences, experience];
    }
    setExperiences(joinedExperiences);
    setShowExperienceForm(false);
    setSelectedExperience(null);
    handleChange("experiences", joinedExperiences);
  }

  useEffect(() => {
    if (getValue("experiences")) setExperiences(getValue("experiences"));
  }, [experiences, getValue]);

  return (
    <div>
      <TitleSection>2. PARCOURS / FORMATIONS</TitleSection>
      <SubTitleSection>Expériences professionnelles</SubTitleSection>
      <div className="flex flex-col gap-2 mb-5">
        {experiences.sort((a, b) => {
          if (a.ending > b.ending) return -1;
          if (a.ending < b.ending) return 1;
          return 0;
        }).map((experience, index) => (
          <SelectedValue
            key={index}
            experience={experience}
            onEdit={() => {
              setSelectedExperience(experience);
              setShowExperienceForm(true);
            }}
            onDelete={() => {
              const filteredExperiences = experiences.filter((exp) => exp.id !== experience.id);
              setExperiences(filteredExperiences);
              handleChange("experiences", filteredExperiences);
              setSelectedExperience(null);
            }}
          />
        ))}
      </div>
      {showExperienceForm && <ExperienceForm
        onSubmit={handleAddExperience}
        onCancel={() => {
          setShowExperienceForm(false)
          setSelectedExperience(null);
        }}
        experience={selectedExperience}
      />}
      {!showExperienceForm && <RoundedButton
        className="!text-blue-dark !border-blue-dark"
        onClick={() => setShowExperienceForm(true)}
        icon={<AddIcon />}
      >
        Ajouter un parcours pro
      </RoundedButton>}
    </div>
  );
};
