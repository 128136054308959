import AvenirText from "../../../atoms/Texts/AvenirText";
import SmallTitle from "../../../atoms/Texts/SmallTitle";
import SubTitle from "../../../atoms/Texts/SubTitle";

export function Experiences({talent}) {
    return (
        <>
            <SubTitle title="Parcours" decoration="small" />
            {talent?.Career?.map((item, index) => (
                <div key={index} className="w-full mt-5">
                    <div className="flex items-center gap-4">
                        <div className="w-[18px] rounded-full aspect-square bg-white border border-gray-dark"></div>
                        <SmallTitle>{new Date(item.beginning).getFullYear()} - {item.isActual ? "Aujourd'hui" : new Date(item.ending).getFullYear()}</SmallTitle>
                    </div>
                    <div className="flex gap-12 pt-2 w-full h-full pl-[9px]">
                        <div className="min-h-max min-w-[1px] bg-gray-dark" />
                        <div className="mt-3.5">
                            <AvenirText weight="heavy" size="sm" color="gray-darkest">{item.title}</AvenirText>
                            <AvenirText size="sm" >{item.description}</AvenirText>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}