import { SubTitle } from "components/atoms";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import { ConnectedUserTemplate } from "components/templates";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  entitiesSelector,
  deleteEntity,
} from "app/store/models/entities.model";
import { CardEntity } from "./_components/CardEntity";
import ModalFormEntity from "../_components/ModalFormEntity";
import ToolbarEditor from "features/Editor/_components/Toolbar";

export default function Entities() {
  const dispatch = useDispatch();
  const { entities } = useSelector(entitiesSelector);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const sendEditEntity = async (entity) => {
    setIsModalOpen(true);
    setSelectedEntity(entity);
  };

  const sendDeleteEntity = async (entity) => {
    if (!entity.id) return;
    dispatch(deleteEntity(entity.id));
  };

  useEffect(() => {
    if (!isModalOpen) setSelectedEntity(null);
  }, [isModalOpen]);


  return (
    <ConnectedUserTemplate>
      <ToolbarEditor
        description={`${entities.length} entités`}
      />
      <div className="w-full max-w-4xl m-auto my-10">
        <div className="border-b border-blue-dark flex items-end
         justify-between">
          <SubTitle>ENTITÉS / FILIALES</SubTitle>
          <RoundedButton
            isFill={true}
            className="mb-2"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Créer une entité
          </RoundedButton>
        </div>
        <ul>
          {entities.map((entity, index) => (
            <CardEntity
              key={index}
              entity={entity}
              sendEditEntity={sendEditEntity}
              sendDeleteEntity={sendDeleteEntity}
            />
          ))}
        </ul>
      </div>
      <ModalFormEntity
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        entity={selectedEntity}
      />
    </ConnectedUserTemplate>
  );
}
