import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchEntities,
  fetchEditEntity,
  fetchCreateEntity,
  fetchDeleteEntity,
} from "api/entities";
import { fetchUploadFile } from "api/file";

const initialState = {
  error: "",
  loading: false,
  entities: [],
};

export const getEntities = createAsyncThunk("entities/getAll", async () => {
  const entities = await fetchEntities();
  return entities;
});

export const createEntities = createAsyncThunk(
  "entities/create",
  async (entity) => {
    const response = await fetchCreateEntity(entity);
    console.log("response", response);
    const image = await fetchUploadFile(response.id, "entity", entity.avatar);
    console.log("image", image);
    return response;
  }
);

export const editEntity = createAsyncThunk("entities/edit", async (entity) => {
  const response = await fetchEditEntity(entity);
  return response;
});

export const deleteEntity = createAsyncThunk("entities/delete", async (id) => {
  const response = await fetchDeleteEntity(id);
  return response;
});

export const entitiesSlicer = createSlice({
  name: "entities",
  initialState,
  reducers: {
    removeEntity: (state, action) => {
      const index = state.entities.findIndex((item) => item.id === action.payload);
      state.entities.splice(index, 1);
    },
    addEntity: (state, action) => {
      state.entities.push(action.payload.entity);
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(editEntity.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(editEntity.fulfilled, (state, action) => {
        state.error = "";
        state.loading = false;
        const entityIndex = state.entities.findIndex(
          (item) => item.id === action.payload.id
        );
        state.entities[entityIndex] = action.payload;
      })
      .addCase(editEntity.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });


    builder
      .addCase(createEntities.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(createEntities.fulfilled, (state, action) => {
        state.error = "";
        state.loading = false;
        state.entities.push(action.payload);
      })
      .addCase(createEntities.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });

    builder
      .addCase(getEntities.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        const entities = action.payload;
        state.error = "";
        state.loading = false;
        state.entities = entities;

      })
      .addCase(getEntities.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });

    builder
      .addCase(deleteEntity.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.error = "";
        state.loading = false;
        const index = state.entities.findIndex(
          (item) => item.id === action.payload.id
        );
        state.entities.splice(index, 1);
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { removeEntity, addEntity } = entitiesSlicer.actions;

export const entitiesSelector = (state) => state.entities;

export default entitiesSlicer.reducer;
