import { Languages, Experiences, Formations, Studies } from "./molecules"

export default function SecondaryInformations({talent}) {

    return (
        <div className="h-full col-span-8 col-start-4 xl:col-span-4 xl:block">
            <Languages talent={talent} />
            <Experiences talent={talent} />
            <Formations talent={talent} />
            <Studies talent={talent} />
        </div>
    )
}