import { fetchStats } from "api/stats";
import { ConnectedUserTemplate } from "components/templates";
import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';



export default function Stats() {
    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend
    );

    const [stats, setStats] = useState(null);

    const data = {
        // labels: Object.keys(...stats?.nbTalentsByEntity),
        labels: ["Agence RJS", "Porte 7", "RJS Slides", "RJS Production", "RJS togather"],
        datasets: [
            {
                label: 'Nb talents par entité',
                // data: Object.values(...stats?.nbTalentsByEntity),
                data: [10, 55, 20, 35, 3],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };


    const getStats = async () => {
        const response = await fetchStats();
        console.log(response?.nbTalentsByEntity)
        setStats(response);
        console.log(Object?.keys(...response?.nbTalentsByEntity))
    }

    const nineboxColor = (value) => {
        switch (value[0]) {
            case "A1":
                return "#4763FF";
            case "A2":
                return "#7A8DFA";
            case "A3":
                return "#7A8DFA";
            case "B1":
                return "#0027FF";
            case "B2":
                return "#4763FF";
            case "B3":
                return "#7A8DFA";
            case "C1":
                return "#0027FF";
            case "C2":
                return "#0027FF";
            case "C3":
                return "#4763FF";
            default:
                return "#7A8DFA";
        }
    }


    useEffect(() => {
        getStats();
    }, [])

    return (
        <ConnectedUserTemplate>
            <div className=" w-full flex flex-col mx-auto gap-12 max-w-[1644px] mt-9 ">
                <div className="w-full grid grid-cols-9 gap-[100px]">
                    <div className="col-span-3 bg-white h-40 shadow-[0_3px_16px_0_rgba(0,0,0,0.16)] flex justify-center items-center">
                        <div className="flex flex-col items-center justify-center">
                            <p className="text-blue-dark text-[40px] cera-bold-font leading-[48px]">{stats?.totalTalents}</p>
                            <p className="uppercase text-xl text-[#3B3B3B] cera-light-font">Talents
                                {/* <span className="cera-medium-font">(+2%)</span> */}
                            </p>
                        </div>
                    </div>
                    <div className="col-span-3 bg-white h-40 shadow-[0_3px_16px_0_rgba(0,0,0,0.16)] flex justify-center items-center">
                        <div className="flex flex-col items-center justify-center">
                            <p className="text-blue-dark text-[40px] cera-bold-font leading-[48px]">{stats?.nbTalentsWithoutSuccessor}</p>
                            <p className="uppercase text-xl text-[#3B3B3B] cera-light-font">talents SANS SUCCESSEUR</p>
                        </div>
                    </div>
                    <div className="col-span-3 bg-white h-40  flex justify-center items-center">
                        <div className="flex w-full h-full">
                            <div className="flex items-center justify-center h-full w-1/2 bg-[#00A8FF] shadow-[0_3px_16px_0_rgba(0,0,0,0.16)]" style={{ width: `${stats?.percentGender?.male}%` }}>
                                <div className="flex flex-col items-center justify-center">
                                    <p className="text-white text-[40px] cera-bold-font leading-[48px]">{stats?.percentGender?.male}%</p>
                                    <p className="uppercase text-xl text-white cera-light-font">HOMMES</p>
                                </div>
                            </div>
                            <div className="flex items-center justify-center h-full w-1/2 bg-pink shadow-[0_3px_16px_0_rgba(0,0,0,0.16)]" style={{ width: `${stats?.percentGender?.female}%` }}>
                                {stats?.percentGender?.female > 10 && <div className="flex flex-col items-center justify-center">
                                    <p className="text-white text-[40px] cera-bold-font leading-[48px]">{stats?.percentGender?.female}%</p>
                                    <p className="uppercase text-xl text-white cera-light-font">FEMMES</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-10 gap-4 mb-20">
                    <div className="col-span-5 bg-white p-[30px] h-[628px]">
                        <p className="uppercase text-2xl text-gray-dark cera-light-font">talent par entité</p>
                        <div className="h-full w-full flex items-center justify-center ">
                            <div className="w-[90%] h-[90%]">
                        <Radar data={data}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 bg-white p-[30px] h-[628px]">
                        <p className="uppercase text-2xl text-gray-dark cera-light-font">répartition des talents</p>
                        <div className="grid w-full gap-2 grid-cols-3 mt-4">
                            {stats?.nineBoxAverage?.map((item, index) => (
                                <div key={index} className={`h-32 hover:scale-[1.03] duration-200 cursor-pointer relative border border-[#CCC] flex items-center justify-center`} style={{ backgroundColor: nineboxColor(Object.keys(item)) }}>
                                    <p className="avenir-medium-font text-[19px] text-white uppercase absolute top-2.5 left-2.5">{Object.keys(item)}</p>
                                    <p className="avenir-medium-font text-2xl text-white uppercase">{Object.values(item)}%</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ConnectedUserTemplate>
    )
}