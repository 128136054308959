import joinClasses from "helpers/joinClasses";
import Card from "./Card";

export function SquareCard({ children, className = "", ...props }) {
  return (
    <Card className={joinClasses(className, "bg-white")} {...props}>
      {children}
    </Card>
  );
}
