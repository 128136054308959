import joinClasses from "helpers/joinClasses";
import { Input } from "./Inputs";

export function InputCheckbox({ className, title, ...props }) {
  return (
    <label className="flex items-center gap-2 cursor-pointer text-sm text-blue-dark cera-font text-sm">
      < Input
        {...props}
        type="checkbox"
        className={
          joinClasses(
            className,
            "form-checkbox text-blue-dark border-blue-dark"
          )
        }
      />
      {title}
    </label >
  );
}
