/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'; import Home from "features/Home";
import Entities from "features/Editor/entities/EntitiesList/entities.page";
import { CreateTalent } from "features/Talents/CreateTalent/createTalent.page";
import { Talent } from "features/Talents/Talent/talent.page";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEntities } from "app/store/models/entities.model";
import { getTalents } from "app/store/models/talents.model";
import TalentsEditor from "features/Editor/talents";
import Stats from "features/Stats";

export default function DefaultRouter() {
  let isFirstRender = true;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      dispatch(getEntities());
      dispatch(getTalents());
    }
  }, [isFirstRender, dispatch]);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/editor/talents" element={<TalentsEditor />} />
        <Route path="/editor/talents/create" element={<CreateTalent />} />
        <Route path="/editor/entities" element={<Entities />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/talents/:id" element={<Talent />} />
      </Routes>
    </BrowserRouter>
  );
}