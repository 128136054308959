import joinClasses from "helpers/joinClasses";

export default function SubTitle({
    title,
    rightSide = null,
    decoration = false,
    className = "",
    succession = false,
    ...props
}) {
    return (
        <div className={`flex items-center ${rightSide && "justify-between"} ${decoration === "big" && `border-b border-[#CCCCCC] ${title === "Successions" ? "pb-[50px]" : "pb-5"} mb-7`}`} {...props}>
            <div>
                <h2 className={joinClasses(className,`text-xl avenir-medium-font text-gray-darkest uppercase tracking-[0.26em]`)}>{title}</h2>
                {decoration === "small" && <div className="h-0.5 bg-gray-darkest w-5 mt-2 " />}
            </div>
            <div>
                {rightSide}
            </div>
        </div>
    );
}