import joinClasses from "helpers/joinClasses";
import AvenirText from "../../../atoms/Texts/AvenirText";

export function TalentCard({ talent, onClick = null, ...props }) {
  if (!talent) return null;
  return (
    <div
      onClick={onClick}
      {...props}
      className={joinClasses(
        "flex flex-col items-center min-w-[240px] sm:min-w-[160px] xl:min-w-[180px] drop-shadow-sm transition-all ",
        onClick ? "cursor-pointer hover:drop-shadow-xl md:hover:-mt-2" : null
      )}
    >
      <img src={talent?.User?.avatar} alt="avatar" />
      <div className="flex flex-col items-center mt-1">
        <AvenirText weight="black" color="black" size="sm">
          - {talent.ninebox} -
        </AvenirText>
        <AvenirText weight="black" color="blue-dark">
          {talent.User.firstname} {talent.User.lastname}
        </AvenirText>
        <AvenirText size="xs" weight="light" color="grey">
          {talent.job}
        </AvenirText>
        <AvenirText size="xs" weight="heavy" color="black">
          {talent.enterprise}
        </AvenirText>
      </div>
    </div>
  );
}
