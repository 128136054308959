import { SmallRoundedButton } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import ModalFormEntity from "features/Editor/entities/_components/ModalFormEntity";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { talentsSelector } from "app/store/models/talents.model";
import searchInTalents from "helpers/searchInTalents";

export const TalentsSelectionsList = ({
  onCancel,
  onSubmit,
  type = "checkbox",
  defaultSelected = null,
  search = ""
}) => {
  const { talents } = useSelector(talentsSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTalents, setSelectedTalents] = useState([]);

  useEffect(() => {
    if (defaultSelected) setSelectedTalents(defaultSelected);
  }, [defaultSelected]);

  const handleClickOnTalent = (talent) => {
    if (type === "radio") {
      setSelectedTalents(talent);
      return;
    }

    if (selectedTalents.includes(talent)) {
      setSelectedTalents(selectedTalents.filter((t) => t !== talent));
    } else {
      setSelectedTalents([...selectedTalents, talent]);
    }
  }

  const handleSubmit = () => {
    onSubmit(selectedTalents);
  }

  const isPresentInSelectedTalents = (talent) => {
    if (type === "radio") {
      return talent === selectedTalents;
    }
    return selectedTalents.find((t) => t === talent);
  }

  return (
    <div className="bg-white w-full animate__animated animate__fadeIn">
      <ul className="max-h-[400px] overflow-auto">
        {talents
          .filter((talent) => searchInTalents(talent, search))
          .map((talent, index) => (
            <label
              key={talent.id}
              className="bg-white flex justify-between pl-2 pr-5"
            >
              <div className="flex items-center w-full">
                <div className="w-[50px] flex justify-center">
                  <input
                    key={talent.id}
                    type={type}
                    name="talents"
                    value={talent.id}
                    onChange={() => handleClickOnTalent(talent.id)}
                    checked={isPresentInSelectedTalents(talent.id) ? true : false}
                  />
                </div>
                <div
                  className={joinClasses(
                    "grid grid-cols-3 gap-5 items-center w-full py-5",
                    index !== talents.length - 1 &&
                    "border-b border-gray-light"
                  )}
                >
                  <div className="h-[50px] flex items-center gap-10">
                    <img
                      src={talent.User.avatar ? talent.User.avatar : "/assets/icons/default-talent.svg"}
                      alt={talent.User.firstname}
                      className="h-[50px] rounded-full"
                    />
                    <p className="avenir-black-font text-blue-dark text-sm">{talent.User.firstname} {talent.User.lastname}</p>
                  </div>
                  <p className="avenir-light-font text-gray-dark text-sm">
                    {talent.job}
                  </p>
                  <div className="flex justify-end">

                    <img src={talent.Entity.avatar || "/assets/icons/default-entity.svg"} alt={talent.Entity.name} className="h-[50px]" />
                  </div>

                </div>
              </div>
            </label>
          ))}
      </ul>
      <div className="h-[1px] w-11/12 m-auto bg-blue-dark" />
      <div className="flex flex-col lg:flex-row justify-between lg:items-center py-5 gap-5 lg:gap-0 px-8">
        <div className="flex gap-5">
          <SmallRoundedButton
            className="border-gray-light text-gray-light"
            onClick={onCancel}
          >
            Annuler
          </SmallRoundedButton>
          <SmallRoundedButton
            isFill
            onClick={handleSubmit}
          >
            Valider
          </SmallRoundedButton>
        </div>
      </div>
      <ModalFormEntity
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};
