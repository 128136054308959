import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchUploadFile(id, type, avatar) {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("type", type);
  formData.append("avatar", avatar);
  const response = await axiosRequest({
    method: "POST",
    url: "/files/upload",
    data: formData,
    headers: {
      Authorization: getLocalToken(),
    },
  });
  return response;
}
