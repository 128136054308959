import { Icon } from "components/atoms";
import Button from "components/atoms/Buttons/Button";

export function SelectedValue({onDelete, onEdit, hard}) {

    const handlePercent = (percent) => {
        switch (percent) {
          case 1:
            return "15%"
          case 2:
            return "35%"
          case 3:
            return "50%"
          case 4:
            return "75%"
          case 5:
            return "100%"
          default:
            return "0%"
        }
      }

      
    return (
        <div
            className="flex items-center w-full border border-gray-light px-5"
            style={{ backgroundColor: "#ECECEC" }}
          >
            <div className="flex flex-col justify-around w-full py-5 gap-5 ">
              <p className="avenir-font text-gray-dark -mt-1">{hard.name}</p>
              <div className="w-[37%] bg-gray-darkest/[0.13] h-[7px] rounded-full">
                <div className="h-full w-0 bg-blue-dark rounded-full z-10" style={{
                  width:
                    handlePercent(hard.percent)
                }}></div>
              </div>
            </div>
            <div className="flex gap-5">
              <Button
                className="h-[30px] w-[30px] border border-blue-dark rounded-full text-sm text-blue-dark flex justify-center items-center"
                onClick={onEdit}
              >
                <Icon name="edit" />
              </Button>
              <Button
                className="h-[30px] w-[30px] border border-red rounded-full text-sm text-red flex justify-center items-center"
                onClick={onDelete}
              >
                <Icon name="close" />
              </Button>
            </div>
          </div>
    )
}