const { default: joinClasses } = require("helpers/joinClasses")

const TitleSection = ({ children, className = "" }) => {
    return (
        <h1 className={joinClasses("cera-regular-font text-blue-dark text-[19px] tracking-[5px]	mb-6 uppercase border-b border-blue-dark", className)}>
            {children}
        </h1 >
    )
}

export default TitleSection