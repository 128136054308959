import joinClasses from "helpers/joinClasses";

export function Input({ register = () => { }, name = "", required = false, rule = {}, className, ...props }) {
  return (
    <input
      {...register(name, {
        required: required,
        ...rule,
      })}
      required={required}
      name={name}
      className={joinClasses(className, "")}
      {...props}
    />
  );
}
