import joinClasses from "helpers/joinClasses";
import { SubSection } from "./SubSection";

export const Section = ({
  section,
  activeSection = { id: 1, subId: 1 },
  status = "none",
  isLast = false,
  setActiveSection = () => { },
}) => {
  const getStatusSubSection = (
    subSectionId,
    activeSubSectionId,
    activeSectionId
  ) => {
    if (status === "done") return "done";
    if (activeSectionId !== section.id) return "none";
    if (subSectionId === activeSubSectionId) return "active";
    if (subSectionId < activeSubSectionId) return "done";
    return "none";
  };

  return (
    <li className="flex gap-4 flex-col xl:flex-row w-max" id={`section-${section.id}`}>
      <div className="flex xl:flex-col items-center gap-2 md:gap-4 xl:gap-1">
        <span
          onClick={() =>
            section.sections.length > 0
              ? setActiveSection({ id: section.id, subId: 1 })
              : setActiveSection({ id: section.id, subId: 0 })
          }
          className={joinClasses(
            "h-[40px] min-h-[40px] min-w-[40px] w-[40px] flex justify-center items-center border rounded-full avenir-ligth-font cursor-pointer",
            status === "active" && "bg-blue-dark text-white",
            status === "done" && "bg-white text-blue-dark",
            status === "none" && "bg-gray-light border-gray-light text-white"
          )}
        >
          {section.id}
        </span>
        {!isLast && (
          <div
            className={joinClasses(
              "w-[20px] md:w-[50px] min-h-[1px] xl:w-[1px] xl:min-h-[20px] xl:h-full",
              status === "active" && "bg-blue-dark",
              status === "done" && "bg-blue-dark",
              status === "none" && "bg-gray-light"
            )}
          />
        )}
      </div>
      <div className="hidden xl:block">
        <h1
          onClick={() =>
            section.sections.length > 0
              ? setActiveSection({ id: section.id, subId: 1 })
              : setActiveSection({ id: section.id, subId: 0 })
          }
          className={joinClasses(
            "uppercase avenir-ligth-font mt-1.5 cursor-pointer",
            status === "active" && "text-blue-dark",
            status === "done" && "text-gray-darkest",
            status === "none" && "text-gray-darkest"
          )}
        >
          {section.title}
        </h1>
        <ul className="pt-1">
          {section.sections.map((subSection, index) => (
            <SubSection
              subSection={subSection}
              key={index}
              status={getStatusSubSection(
                subSection.id,
                activeSection.subId,
                activeSection.id
              )}
              onClick={() =>
                setActiveSection({ id: section.id, subId: subSection.id })
              }
            />
          ))}
        </ul>
      </div>
    </li>
  );
};
