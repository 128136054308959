import SubTitle from "../../../atoms/Texts/SubTitle";

export function Languages({talent}){
    return (
        <div className="border-y-[#CCC] border-y flex items-center gap-8 py-8 w-full mb-8">
                <SubTitle className="pt-1" title="Langues" />
                <div className="w-full h-full flex gap-2">
                    {talent?.TalentLanguage?.map((item, index) => (
                        <span key={index} className={`fi-${item?.Language?.code} block rounded-full h-8 w-8 bg-center bg-cover bg-no-repeat`} />))}
                </div>
            </div>
    )
}