import { AuthTemplate } from "components/templates";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Form from "components/molecules/Form";
import {
  InputCheckbox,
  InputCode,
  SquareButton,
  SquareInputText,
} from "components/atoms";
import { ErrorForm } from "components/atoms";
import { fetchRegister, fetchRegisterEmail, fetchVerifyEmail } from "api/auth";

export default function Register({ defaultStep = 1 }) {
  let navigate = useNavigate();

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    terms: false,
  });
  const [validationCase, setValidationCase] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(defaultStep);

  async function sendRegistration(event) {
    try {
      event.preventDefault();
      setLoading(true);
      setError(null);

      await fetchRegisterEmail(user.email);

      setStep(2);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  async function sendValidationCode(event) {
    try {
      event.preventDefault();
      setLoading(true);
      setError(null);
      if (!validationCase.length) {
        setError("Veuillez entrer le code de validation");
        setLoading(false);
        return;
      }

      await fetchVerifyEmail(validationCase, user.email);

      setStep(3);
      setLoading(false);
    } catch (error) {

      setError(error.message);
      setLoading(false);
    }
  }

  async function sendInformations(event) {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const result = await fetchRegister(user);
    if (result.status === "error") {
      setError(result.response);
      setLoading(false);
      return;
    }

    setLoading(false);
    navigate("/");
  }
  if (step === 1) {
    return (
      <AuthTemplate>
        <div>
          <h2 className="cera-black-font text-blue-dark text-3xl leading-7">
            Inscrivez-vous
          </h2>
          <p>
            ou{" "}
            <Link to="/" className="underline">
              connectez-vous à votre compte
            </Link>
          </p>
        </div>
        <Form
          className="w-full flex flex-col gap-3 mt-5"
          onSubmit={sendRegistration}
        >
          <SquareInputText
            type="email"
            placeholder="E-mail"
            name="email"
            id="email"
            required={true}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <InputCheckbox
            type="checkbox"
            title="J’​accepte les conditions d’utilisation de Talents-View"
            required={true}
            onChange={(e) => setUser({ ...user, terms: e.target.checked })}
          />

          {error && <ErrorForm message={error} />}
          <br />

          {loading ? (
            <SquareButton>Chargement...</SquareButton>
          ) : (
            <SquareButton type="submit">S’inscrire</SquareButton>
          )}
        </Form>
      </AuthTemplate>
    );
  } else if (step === 2) {
    return (
      <AuthTemplate>
        <div>
          <h2 className="cera-black-font text-blue-dark text-3xl leading-7">
            Code de confirmation
          </h2>
          <p>Vous avez reçu un mail.</p>
        </div>
        <Form
          className="w-full flex flex-col gap-3 mt-5 animate__animated animate__slideInRight"
          onSubmit={sendValidationCode}
        >
          <InputCode setCode={setValidationCase} />

          {error && <ErrorForm message={error} />}
          <br />

          {loading ? (
            <SquareButton>Chargement...</SquareButton>
          ) : (
            <SquareButton type="submit">Confirmer</SquareButton>
          )}
        </Form>
      </AuthTemplate>
    );
  } else if (step === 3) {
    return (
      <AuthTemplate>
        <div>
          <h2 className="cera-black-font text-blue-dark text-3xl leading-7">
            Informations
          </h2>
          <p>saisissez vos informations personnelles et votre mot de passe.</p>
        </div>
        <Form
          className="w-full flex flex-col gap-3 mt-5 animate__animated animate__slideInRight"
          onSubmit={sendInformations}
        >
          <SquareInputText
            type="text"
            placeholder="Prénom"
            name="firstname"
            required={true}
            onChange={(e) => setUser({ ...user, firstname: e.target.value })}
          />
          <SquareInputText
            type="text"
            placeholder="Nom"
            name="lastname"
            required={true}
            onChange={(e) => setUser({ ...user, lastname: e.target.value })}
          />
          <SquareInputText
            type="password"
            placeholder="Mot de passe"
            name="password"
            required={true}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />

          {error && <ErrorForm message={error} />}
          <br />

          {loading ? (
            <SquareButton>Chargement...</SquareButton>
          ) : (
            <SquareButton type="submit">Valider</SquareButton>
          )}
        </Form>
      </AuthTemplate>
    );
  }
}
