import Button from "components/atoms/Buttons/Button";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import Toolbar from "components/molecules/Toolbar";
import joinClasses from "helpers/joinClasses";
import { useLocation, useNavigate } from "react-router-dom";

export default function ToolbarEditor({ description = "" }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    return (
        <Toolbar rightSide>
            <p className="font-bold">{description}</p>
            <div className="flex items-center gap-5">
                <div className="flex items-center gap-5">
                    <Button
                        className={joinClasses("px-2 py-1 text-xs avenir-medium-font text-gray-dark", pathname === "/editor/entities" && "border-b border-blue-dark !text-blue-dark")}
                        onClick={() => navigate("/editor/entities")}>
                        Entités/Filiales
                    </Button>
                    <div className="h-[20px] w-[1px] bg-gray-dark"></div>
                    <Button
                        className={joinClasses("px-2 py-1 text-xs avenir-medium-font text-gray-dark", pathname === "/editor/talents" && "border-b border-blue-dark !text-blue-dark")}
                        onClick={() => navigate("/editor/talents")}>
                        Base globale
                    </Button>
                </div>
                <RoundedButton
                    className="bg-gray-dark border-gray-dark hover:bg-gray-dark hover:border-gray-dark hover:text-white"
                    onClick={() => navigate("/")}>
                    Quitter l'éditeur
                </RoundedButton>
            </div>
        </Toolbar>
    )
}