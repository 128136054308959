/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "features/_loader";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AuthenticationRouter from "./authentication";
import DefaultRouter from "./default";
import { checkToken, userSelector } from "app/store/models/user.model";


function App() {
    const dispatch = useDispatch();
    const { loading, token, user, error } = useSelector(userSelector);
    let isFirstRender = true;

    useEffect(() => {
        if (!token || !isFirstRender) return;
        isFirstRender = false;
        dispatch(checkToken());
    }, []);


    // if (loading) {
    //     return <Loader />;
    // } else if (!token || !user.id || error.length) {
    //     return <AuthenticationRouter />;
    // } else if (token && user.id) {
    //     console.log("DefaultRouter ");
    //     return <DefaultRouter />;
    // }

    return (
        <React.Fragment>
            {loading && <Loader />}
            {(!token || error.length) ? <AuthenticationRouter /> : null}
            {user.id && <DefaultRouter />}
        </React.Fragment>
    )
}

export default App;
