import { useSelector } from "react-redux";
import { ConnectedUserTemplate } from "../../components/templates";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import Toolbar from "components/molecules/Toolbar";
import { useNavigate } from "react-router-dom";
import { Filter } from "./_components/Filter";
import { TalentList } from "./_components/List";
import { talentsSelector } from "app/store/models/talents.model";
import { useState } from "react";


export default function Home() {
  const { talents } = useSelector(talentsSelector);
  let navigate = useNavigate();

  const [allTalents, setAllTalents] = useState(talents || [])


  return (
    <ConnectedUserTemplate>
      <Toolbar rightSide={true}>
        <p className="font-bold">{allTalents.length} talents</p>
        <RoundedButton isFill={true} onClick={() => navigate("/editor/talents")}>
          Mode éditeur
        </RoundedButton>
      </Toolbar>

      {/* Filter & List */}
      <section className="flex w-full flex-col  md:flex-row lg:justify-between xl:gap-24 xl:justify-center">
        <Filter allTalents={allTalents} setAllTalents={setAllTalents} />
        <TalentList data={allTalents} setAllTalents={setAllTalents} />
      </section>
    </ConnectedUserTemplate>
  );
}