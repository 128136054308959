import { Icon } from "components/atoms";
import Button from "components/atoms/Buttons/Button";

export const SelectedValue = ({
  onEdit,
  onDelete,
  title = "",
  avatar = null,
}) => {
  return (
    <div
      className="flex items-center w-full border border-gray-light px-5"
      style={{ backgroundColor: "#ECECEC" }}
    >
      <div className="flex items-center w-full py-5">
        {avatar && (
          <div className="w-[70px] md:w-[200px] h-[50px] flex items-center">
            <img src={avatar ? avatar : ""} alt={title} className="h-[50px]" />
          </div>
        )}
        <p className="avenir-font text-gray-dark ">{title}</p>
      </div>
      <div className="flex gap-5">
        <Button
          className="h-[30px] w-[30px] border border-blue-dark rounded-full text-sm text-blue-dark flex justify-center items-center"
          onClick={onEdit}
        >
          <Icon name="edit" />
        </Button>
        <Button
          className="h-[30px] w-[30px] border border-red rounded-full text-sm text-red flex justify-center items-center"
          onClick={onDelete}
        >
          <Icon name="close" />
        </Button>
      </div>
    </div>
  );
};
