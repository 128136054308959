import { SquareCard, SubTitle } from "components/atoms";
import { Icon } from "components/atoms/Icon";
import joinClasses from "helpers/joinClasses";

export default function Modal({
  isOpen = false,
  setIsOpen = () => { },
  title = "",
  children,
}) {
  return (
    <div
      className={joinClasses("fixed top-0 left-0 z-30 animate__animated animate__fadeIn",
        isOpen ? "block" : "hidden"
      )}
    >
      <div
        className="bg-blue-dark bg-opacity-75 h-screen w-screen"
        onClick={() => setIsOpen(false)}
      ></div>
      <SquareCard className="absolute top-1/2 left-1/2 transform  -translate-x-2/4 -translate-y-2/4 w-11/12 md:w-full  max-w-3xl py-5 px-8 max-h-screen overflow-auto">
        <Icon
          name="close"
          className="text-2xl text-blue-dark cursor-pointer absolute top-3 right-5"
          onClick={() => setIsOpen(false)}
        />
        <div>
          <SubTitle>{title}</SubTitle>
        </div>
        <div className="pt-10 pb-4">{children}</div>
      </SquareCard>
    </div>
  );
}
