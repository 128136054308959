import { SmallRoundedButton } from "components/atoms";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import joinClasses from "helpers/joinClasses";
import ModalFormEntity from "features/Editor/entities/_components/ModalFormEntity";
import { useState } from "react";
import { useSelector } from "react-redux";
import { entitiesSelector } from "app/store/models/entities.model";

export const Entities = ({ setIsOpen, setSelectedEntity, selectedEntity }) => {
  const { entities } = useSelector(entitiesSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntityLocal, setSelectedEntityLocal] = useState(null);

  return (
    <div className="bg-white w-full animate__animated animate__fadeIn">
      <ul className="max-h-[400px] overflow-auto">
        {entities.map((entity, index) => (
          <label
            key={entity.id}
            className="bg-white flex justify-between pl-2 pr-5"
          >
            <div className="flex items-center w-full">
              <div className="w-[50px] flex justify-center">
                <input
                  key={entity.id}
                  type="radio"
                  name="entity"
                  value={entity.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedEntityLocal(entity);
                    }
                  }}
                  defaultChecked={selectedEntity === entity.id}
                />
              </div>
              <div
                className={joinClasses(
                  "flex items-center w-full py-5",
                  entities.length !== index &&
                  "border-b border-gray-light"
                )}
              >
                <div className="w-[200px] h-[50px] flex items-center">
                  <img
                    src={entity.avatar ? entity.avatar : ""}
                    alt={entity.title}
                    className="h-[50px]"
                  />
                </div>
                <p className="avenir-font text-gray-dark ">{entity.title}</p>
              </div>
            </div>
          </label>
        ))}
      </ul>
      <div className="h-[1px] w-11/12 m-auto bg-blue-dark" />
      <div className="flex flex-col lg:flex-row justify-between lg:items-center py-5 gap-5 lg:gap-0 px-8">
        <RoundedButton
          isFill
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
          className="max-w-fit"
        >
          Créer une entité
        </RoundedButton>
        <div className="flex gap-5">
          <SmallRoundedButton
            className="border-gray-light text-gray-light"
            onClick={() => setIsOpen(false)}
          >
            Annuler
          </SmallRoundedButton>
          <SmallRoundedButton
            isFill
            onClick={() => {
              setSelectedEntity(selectedEntityLocal);
              setIsOpen(false);
            }}
          >
            Ajouter
          </SmallRoundedButton>
        </div>
      </div>
      <ModalFormEntity
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};
