import Letter from "components/atoms/Icons/Letter.icon";
import Pdf from "components/atoms/Icons/Pdf.icon";

export const triptychData = [
    {
        id: 2,
        title: "ENVOYER UN EMAIL",
        icon: <Letter />,
        href: "",
        style: "small"
    },
    {
        id: 3,
        title: "DOCUMENTS ADMINISTRATIFS",
        icon: <Pdf />,
        href: "",
        style: "small"
    },
];

export default triptychData;
