import { IconButton } from "components/atoms/Buttons/IconButton";
import Card from "components/atoms/Cards/Card";

export function CardEntity({
  entity,
  sendEditEntity = () => { },
  sendDeleteEntity = () => { },
  ...props
}) {
  return (
    <Card className="bg-white flex justify-between my-1.5 py-5">
      <div className="flex items-center">
        <div className="w-[200px] h-[50px] flex items-center">
          <img
            src={entity.avatar ? entity.avatar : ""}
            alt={entity.title}
            className="h-[50px]"
          />
        </div>
        <p className="avenir-font text-gray-dark">{entity.title}</p>
      </div>
      <div className="flex gap-4 items-center">
        <IconButton
          icon="edit"
          className="border-blue-dark text-blue-dark"
          onClick={() => sendEditEntity(entity)}
        />
        <IconButton
          icon="close"
          className="border-red text-red"
          onClick={() => sendDeleteEntity(entity)}
        />
      </div>
    </Card>
  );
}
