import joinClasses from "helpers/joinClasses";
import Button from "./Button";

function RoundedButton({ children, className = "", isFill = false, icon, ...props }) {
  return (
    <Button
      className={joinClasses(
        "text-xs transition flex items-center gap-2.5 text-center rounded-full  py-2.5 border border-white cursor-pointer tracking-wider cera-medium-font",
        isFill
          ? "bg-blue-dark text-white px-10"
          : "bg-transparent  text-white hover:bg-white hover:text-blue-dark px-3 ",
        className
      )}
      {...props}
    >
      {icon}
      {children}
    </Button>
  );
}

export default RoundedButton;
