import joinClasses from "helpers/joinClasses";

export function Textarea({ className, title, children, ...props }) {
  return (
    <div className="flex flex-col w-full">
      <p className="text-blue-dark cera-font text-sm mb-2">{title}</p>
      <textarea className={joinClasses("w-full min-h-[74px] p-2 border border-gray-light w-full text-sm", className)} {...props}>
        {children}
      </textarea>
    </div>
  );
}
