import { entitiesSelector } from "app/store/models/entities.model";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import TitleSection from "features/Talents/CreateTalent/_components/Title";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Entities } from "./Entities";
import { Job } from "./Job";
import { SelectedValue } from "./SelectedValue";
import { Service } from "./Service";
import AddIcon from "components/atoms/Icons/Add.icon";

export const Entity = ({ handleChange, getValue }) => {
  const { entities } = useSelector(entitiesSelector);
  const [showEntityArray, setShowEntityArray] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const [service, setService] = useState(null);
  const [showService, setShowService] = useState(false);

  const [job, setJob] = useState(null);
  const [showJob, setShowJob] = useState(false);

  const displayEntityArray = (e) => {
    e.preventDefault();
    setShowEntityArray(!showEntityArray);
  };

  const handleSelectEntity = (entity) => {
    setSelectedEntity(entity);
    handleChange("entityId", entity?.id);
  };
  const handleRemoveEntity = () => {
    setSelectedEntity(null);
    handleChange("entityId", "");
  }

  const handleSelectService = (service) => {
    setService(service);
    handleChange("service", service);
  }
  const handleRemoveService = () => {
    setService(null);
    handleChange("service", "");
  }

  const handleSelectJob = (job) => {
    setJob(job);
    handleChange("job", job);
  }
  const handleRemoveJob = () => {
    setJob(null);
    handleChange("job", "");
  }

  useEffect(() => {

    if (getValue("entityId") && entities.length > 0) {
      setSelectedEntity(entities.find((entity) => entity.id === getValue("entityId")));
    }
    setService(getValue("service"));
    setJob(getValue("job"));
  }, [entities, getValue]);

  return (
    <div>
      {/* ENTITE / FINLIALE */}
      <div>
        <TitleSection>1. Informations personnelles</TitleSection>
        <SubTitleSection>Entité / filiale*</SubTitleSection>

        <div className={joinClasses(showEntityArray ? "block" : "hidden")}>
          <Entities
            setIsOpen={setShowEntityArray}
            setSelectedEntity={handleSelectEntity}
            selectedEntity={selectedEntity}
          />
        </div>

        {!showEntityArray && !selectedEntity &&
          <RoundedButton
            className="!text-blue-dark !border-blue-dark"
            onClick={displayEntityArray}
            icon={<AddIcon />}
          >
            Attribuer une entité / filiale
          </RoundedButton>}

        {!showEntityArray && selectedEntity && <SelectedValue
          title={selectedEntity && selectedEntity.title}
          avatar={selectedEntity && selectedEntity.avatar}
          onEdit={() => {
            setShowEntityArray(true);
          }}
          onDelete={handleRemoveEntity}
        />}

        <hr className="border-0 bg-gray-light h-[1px] my-6" />
      </div>

      {/* SERVICE */}
      <div>
        <SubTitleSection>Service</SubTitleSection>
        <div className={joinClasses(showService ? "block" : "hidden")}>
          <Service
            setShowService={setShowService}
            service={service}
            setService={handleSelectService}
          />
        </div>

        {!showService && !service &&
          <RoundedButton
            className="!text-blue-dark !border-blue-dark"
            onClick={() => setShowService(true)}
            icon={<AddIcon />}
          >
            Attribuer un service
          </RoundedButton>}
        {!showService && service && (
          <SelectedValue
            title={service && service}
            onEdit={() => setShowService(true)}
            onDelete={handleRemoveService}
          />
        )}

        <hr className="border-0 bg-gray-light h-[1px] my-6" />
      </div>

      {/* FONCTION / POSTE */}
      <div>
        <SubTitleSection>Fonction / poste</SubTitleSection>
        <div className={joinClasses(showJob ? "block" : "hidden")}>
          <Job
            setShowJob={setShowJob}
            job={job}
            setJob={handleSelectJob}
          />
        </div>

        {!showJob && !job &&
          <RoundedButton
            className="!text-blue-dark !border-blue-dark"
            onClick={() => setShowJob(true)}
            icon={<AddIcon />}
          >
            Attribuer un service
          </RoundedButton>}
        {!showJob && job && (
          <SelectedValue
            title={job && job}
            onEdit={() => setShowJob(true)}
            onDelete={handleRemoveJob}
          />
        )}
      </div>
    </div >
  );
};
