import axios from "axios";
import getLocalToken from "./getLocalToken";

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const axiosRequest = async (axiosParams) => {
  try {
    const response = await axios.request({
      ...axiosParams,
      headers: {
        Authorization: getLocalToken(),
      },
    })
    return response.data.data;
  } catch (error) {
    let message = error?.response?.data?.error?.message;

    // if the token is expired, we remove it from the local storage and reload the page
    if (message === "Redirect-to-login") {
      localStorage.removeItem("::token");
      window.location.reload();
    }

    // if the message is empty, we set a default message
    if (!message) {
      if (error.message === "Network Error") message = "Impossible de se connecter au serveur";
      else message = "Une erreur est survenue";
    }
    throw new Error(message);
  }
}
