import joinClasses from "helpers/joinClasses";
import { Input } from "./Inputs";
import { useState } from "react";

export function LineInputSearch({
  className,
  title = "",
  list = [],
  searchBy = "title",
  required = false,
  onChange = () => { },
  ...props
}) {

  const [value, setValue] = useState("")
  return (
    <div className="w-full">
      <span className="text-blue-dark cera-font text-sm">
        {title}
        {title && required && "*"}
      </span>
      <div className="relative">
        <Input
          {...props}
          required={required}
          className={joinClasses(
            className,
            "placeholder-gray-light text-gray-darkest border-0 border-b border-gray-light focus:border-blue-dark px-0 pt-2 w-full avenir-light-font transition-all text-sm outline-none input-search"
          )}
          value={value}
          onChange={(e) => {
            onChange(e.target.value)
            setValue(e.target.value)
          }}
        />
        <ul className={joinClasses("absolute w-full bg-white z-10 top-[29px] input-search-list")}>
          {value.length > 0 &&
            list.filter(item => item[searchBy].toLowerCase().includes(value.toLowerCase())).map((item, index) => (
              <li key={index} className="text-black py-2 px-4 text-xs font-cera-regular cursor-pointer overflow-hidden whitespace-nowrap w-full overflow-ellipsis text-left hover:bg-gray-lightest" onClick={() => { setValue(item[searchBy]); onChange(item) }}>
                {item[searchBy]}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
