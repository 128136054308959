const { default: joinClasses } = require("helpers/joinClasses")

const SubTitleSection = ({ children, className = "" }) => {
    return (
        <div className="flex items-center gap-2 mb-5">
            <div className="h-[12px] w-[12px] bg-blue-dark rounded-full" />
            <h1 className={joinClasses("avenir-black-font text-[#3B3B3B] mt-0.5 text-xs tracking-[2.5px] uppercase", className)}>
                {children}
            </h1 >
        </div>
    )
}

export default SubTitleSection