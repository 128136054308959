import Card from "components/atoms/Cards/Card";
import talentData from "features/Talents/Talent/data/talent";
import AvenirText from "../../../atoms/Texts/AvenirText";


export function InfoCard({talent}) {

    const theTalent = talentData[0];

    function calculateAge(dateString) {
        const birthDate = new Date(dateString);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--; // Subtract 1 from the age if the current month and day come before the birth month and day
        }
      
        return age;
      }

    return (
        <Card className="shadow-lg w-full flex px-7 justify-between mb-10">
            <div>
                <h2 className="text-[30px] avenir-black-font text-gray-darkest">{talent.ninebox ? talent.ninebox : "- -"}</h2>
                <h1 className="text-2xl avenir-medium-font text-blue-dark">{talent.User?.firstname} {" "} {talent.User?.lastname}</h1>
                <AvenirText size="lg" weight="medium" color="gray-darkest">{talent.job}</AvenirText>
                <AvenirText size="lg" weight="medium" color="gray-darkest">{talent.address} | {calculateAge(talent.birthday)} ans</AvenirText>
            </div>
            <div className="flex items-end flex-col">
                <img alt="img" className="h-[80px] text-right" src={talent.User?.avatar ? talent.User?.avatar : theTalent.avatar} />
                <AvenirText className="mt-2 leading-6" size="lg" weight="medium" color="gray-darkest">{talent.Entity?.title}</AvenirText>
                <AvenirText size="sm" weight="black" color="gray-darkest" className="capitalize">{talent.service}</AvenirText>
            </div>
        </Card>
    )
}