import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userSelector, logout } from "../../../app/store/models/user.model";
import { Notification } from "components/atoms";

export function ProfilLink() {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();

  return (
    <div
      className="flex justify-center items-center gap-1 cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <p className="avenir-black-font text-xs">
        {user.firstname}{" "}
        {user.lastname[0]}
      </p>
      <div className="flex items-center justify-center">
        <div className="lg:relative">
          <i className="icon-params text-xl pl-2 text-black lg:text-white" />
          <div
            className={`${isOpen ? "block" : "hidden"
              } absolute h-1/4 w-screen lg-max:bottom-0  right-0 bg-gray-lightest lg:bg-white shadow-xl text-black rounded-md avenir-medium-font text-sm text-center lg:h-auto lg:w-auto animate__animated animate__fadeInUp z-20 `}
          >
            <span className="absolute top-8 right-10 text-xs flex gap-1 items-center">
              <i className="icon-arrow-light-down lg:hidden" />
            </span>
            <ul className="flex flex-col justify-center h-full">
              <li>
                <Link
                  to="/settings"
                  className="block px-4 py-2 hover:underline"
                >
                  Parametres
                </Link>
              </li>
              <li>
                <button
                  to="/settings"
                  className="block px-4 py-2 hover:underline text-red m-auto"
                  onClick={() => dispatch(logout())}
                >
                  Déconnexion
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex items-center relative pl-4">
        <i className="icon-bell text-black lg:text-beige-lightest text-[19px]" />
        <Notification className="absolute bottom-0 right-0 bg-turquoise text-blue-dark transform translate-x-4 translate-y-2">
          27
        </Notification>
      </div>
    </div>
  );
}
