import RoundedButton from "components/atoms/Buttons/RoundedButton";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";
import TitleSection from "../../../_components/Title";
import { SoftSkillsForm } from "./form";
import { SelectedValue } from "./SelectedValue";
import AddIcon from "components/atoms/Icons/Add.icon";
import { fetchSkills } from "api/skills";
import { get } from "react-hook-form";

export const SoftSkills = ({ handleChange, getValue }) => {

  // STATES
  const [softList, setSoftList] = useState([]);
  const [softs, setSofts] = useState([]);
  const [showSoftForm, setShowSoftForm] = useState(false);
  const [edit, setEdit] = useState("selected")
  const [newSoft, setNewSoft] = useState({
    name: "",
    id: "",
    order: 0,
    type: "soft"
  });

  // FUNCTIONS HELPERS
  const onCancel = () => {
    setShowSoftForm(false)
    setNewSoft({
      name: "",
      id: "",
      order: 0,
      type: "soft"
    });
  }

  // REMOVE SOFT SKILL
  const removeSoft = (index) => {
    const newArray = [...softs];
    const removed = newArray[index];
    newArray.splice(index, 1);
    setSoftList([...softList, removed]);
    setSofts(newArray);
    onCancel()
    handleChange("skillIds", [...getValue("skillIds").filter((item) => item.Type === "hard"), ...newArray]);
  }

  const getSkills = async () => {
    try {
      const response = await fetchSkills();
      setSoftList(response.filter((item) => item.Type === "SOFT"));
    } catch (error) {
      console.log(error);
    }
  }

  const handleAdd = async (skill) => {
    const newArray = [...softs];
    newArray.push(skill);
    setSofts(newArray);
    onCancel()
    handleChange("skillIds", [...getValue("skillIds").filter((item) => item.Type === "hard"), ...newArray]);
  }

  // si on a des softskills enregistrés
  useEffect(() => {
    const getSavedSkills = () => {
      const savedSkills = getValue("skillIds");
      console.log("savedSkills -> ", savedSkills)
      if (savedSkills) {
        const savedSoftskills = savedSkills.filter((item) => item.Type === "soft");
        setSofts(savedSoftskills);
      }
    }
    getSavedSkills();
  }, [getValue])

  useEffect(() => {
    getSkills();
  }, []);

  return (
    <div>
      <TitleSection>3. Compétences</TitleSection>
      <SubTitleSection>Soft skills</SubTitleSection>
      <div className="flex items-center mb-5 flex-wrap gap-[25px]">
        {softs.length > 0 && (
          <div
            className="flex items-center w-full py-3.5 cera-light-font gap-[18px] text-sm border border-gray-light px-5"
            style={{ backgroundColor: "#ECECEC" }}
          >
            {softs.map((item, index) => (
              <SelectedValue key={index} soft={item} onDelete={() => removeSoft(index)} />
            ))}
          </div>
        )}
      </div>
      {showSoftForm && (
        <SoftSkillsForm onCancel={() => onCancel()} onSubmit={(skill) => handleAdd(skill)} softSkills={softList} />
      )}
      {!showSoftForm && (
        <RoundedButton className="!text-blue-dark !border-blue-dark" onClick={() => setShowSoftForm(true)} icon={<AddIcon />}>
          Ajouter un soft skill
        </RoundedButton>
      )}
    </div>
  );
};