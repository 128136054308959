// 1.Informations
import { Identity } from "features/Talents/CreateTalent/forms/informations/Idenity";
import { Hierarchy } from "./forms/informations/Hierarchy";
import { Entity } from "./forms/informations/Entity/index";

// 2.Training
import { Experiences } from "./forms/training/Experiences";
import { Training } from "./forms/training/Training";
import { Studies } from "./forms/training/Studies";

// 3.Skills
import { Languages } from "./forms/skills/Languages";
import { SoftSkills } from "./forms/skills/SoftSkills";
import { HardSkills } from "./forms/skills/HardSkills";

// 4.Projects
import { Projects } from "./forms/projects";

// 5.Succession
import { Succession } from "./forms/succession";

// 6.Evaulations
import { Evaluations } from "./forms/evaluations";

export const breadcrumb = [
  {
    id: 1,
    title: "informations",
    indexComponent: null,
    sections: [
      {
        id: 1,
        title: "Identité",
        component: Identity,
      },
      {
        id: 2,
        title: "Entité / Service / Poste",
        component: Entity,
      },
      {
        id: 3,
        title: "Hiérarchie",
        component: Hierarchy,
      },
    ],
  },
  {
    id: 2,
    title: "parcours / formations",
    indexComponent: null,
    sections: [
      {
        id: 1,
        title: "Expériences professionnelles",
        component: Experiences,
      },
      {
        id: 2,
        title: "Formations professionnelles",
        component: Training,
      },
      {
        id: 3,
        title: "Études",
        component: Studies,
      },
    ],
  },
  {
    id: 3,
    title: "compétences",
    indexComponent: null,
    sections: [
      {
        id: 1,
        title: "Langues",
        component: Languages,
      },
      {
        id: 2,
        title: "Soft skills",
        component: SoftSkills,
      },
      {
        id: 3,
        title: "Hard skills",
        component: HardSkills,
      },
    ],
  },
  {
    id: 4,
    title: "projets & attentes",
    indexComponent: Projects,
    sections: [],
  },
  {
    id: 5,
    title: "succession",
    indexComponent: Succession,
    sections: [],
  },
  {
    id: 6,
    title: "notes et évaluations",
    indexComponent: Evaluations,
    sections: [],
  },
];
