import { Select, SmallRoundedButton, SquareCard } from "components/atoms"

export const HardSkillsForm = ({ onSubmit, onCancel, dotsSelected, setDotsSelected, newHard, setNewHard, hardList, edit, setEdit }) => {

    function handleClick(index) {
        setDotsSelected(index);
        setNewHard({ ...newHard, percent: (index + 1) });
    }

    const handleCancel = (e) => {
        e.preventDefault()
        onCancel()
    }

    const handleSelect = (e) => {
        if(!e.target.value) {
            setNewHard({ ...newHard, name: "", order: 0, id: "", type: "hard" });
            setEdit("selected")
        } else{
            const selected = hardList[e.target.value]
            setEdit(e.target.value)
            setNewHard({ ...newHard, name: selected.name , order: selected.order, id: selected.id, percent: newHard.percent || 1, type: "hard" });
        }
    }

    return (
        <SquareCard className="flex justify-between ">
            <div className="w-1/2">
                <Select title="Compétence" onChange={handleSelect} value={edit}>
                    <option  defaultValue={"selected"}>Choisir une compétence</option>
                    {hardList.sort((a, b) => a.order < b.order ? -1 : 1).map((item, index) => (
                        <option key={index} value={index}>{item.name}</option>
                    ))}
                </Select>
            </div>
            <div>
                <div className="flex flex-col w-full ">
                    <div className="flex flex-col items-center mr-6">
                        <p className="text-center mb-3 text-blue-dark cera-font">Niveau</p>
                        <div className="flex items-center justify-center gap-[3.5px]">
                            {Array.from({ length: 5 }, (item, index) => (
                        <div key={index} className="flex items-center justify-center gap-[3.5px]">
                                    <div   className="w-4 h-4 relative rounded-full flex items-center justify-center border border-blue-dark p-0.5 bg-transparent cursor-pointer" onClick={() => handleClick(index)}>
                                        <button
                                            className={`rounded-full w-2.5 h-2.5  ${dotsSelected >= index ? 'bg-blue-dark' : 'bg-white'
                                                }`}

                                        ></button>
                                        {(index === 0 || index === 2 || index === 4) && <p className="text-gray-dark absolute cursor-pointer cera-font uppercase -bottom-full translate-y-1/2 text-[10px] cera-font">{index === 0 ? "Débutant" : index === 2 ? "Intermédiaire" : index === 4 && "Expert"}</p>}
                                    </div>
                                    {index < 4 &&
                                        <span className="w-7 h-[1px] bg-blue-dark"></span>
                                    }
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="mt-[55px] flex gap-3">
                        <SmallRoundedButton onClick={handleCancel} className="border-gray-light text-gray-light">
                            Annuler
                        </SmallRoundedButton>
                        <SmallRoundedButton onClick={onSubmit} isFill>
                            Ajouter
                        </SmallRoundedButton>
                    </div>
                </div>
            </div>
        </SquareCard>
    )
}