import talentData from "features/Talents/Talent/data/talent";
import SmallTitle from "../../atoms/Texts/SmallTitle";
import { InfoCard, Notes, Projects, Skills, Successions } from "./molecules";

export default function xMainInformations({talent}) {

    const theTalent = talentData[0];

    return (
        <section className="h-full xl:col-span-6 col-span-9">
            <InfoCard talent={talent} />
            <div className="flex items-center gap-3">
                <img className="aspect-square w-12 aspect-square rounded-full" src={theTalent.appreciation.src} alt={theTalent.appreciation.alt}/>
                <SmallTitle>Souhaite plus de responsabilités  <span className="text-gray-dark">(depuis le 06/07/2021)</span></SmallTitle>
            </div>
            <Skills talent={talent} />
            <Projects talent={talent} />
            <Notes talent={talent} />
            <Successions talent={talent} />
        </section>
    )
}