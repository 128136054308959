import joinClasses from "helpers/joinClasses";
import { Input } from "./Inputs";

export function InputRadio({ className, title, name, value, ...props }) {
  return (
    <label className="flex items-center gap-2 cursor-pointer text-sm">
      <Input
        {...props}
        type="radio"
        id={name}
        name={name}
        value={value}
        className={joinClasses(
          className,
          "form-checkbox text-blue-dark border-blue-dark"
        )}
      />
      {title}
    </label>
  );
}
