import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";


export const Ninebox = ({ handleChange, getValue }) => {

    const box = ["A1", "A2", "A3", "B1", "B2", "B3", "C1", "C2", "C3"]
    const [selectedBox, setSelectedBox] = useState("")

    const chooseBox = (item) => {
        if(item !== selectedBox) {
            setSelectedBox(item)
            handleChange("ninebox", item);
        } else {
            setSelectedBox("")
            handleChange("ninebox", "");
        }
    }

    useEffect(() => {
        if (getValue("ninebox")) {
          setSelectedBox(getValue("ninebox"))
        }
      }, [getValue]);
    

  return (
    <div>
      <SubTitleSection>Évaluation nine box</SubTitleSection>
      <p className="cera-light-font text-blue-dark">Sélectionnez la case correspondante :</p>
      <div className="grid w-[55%] gap-2 grid-cols-3 mt-4">
        {box.map((item, index) => (
            <div key={index} onClick={() => chooseBox(item) } className={`h-20 cursor-pointer ${selectedBox !== item ? "bg-[#ECECEC]" : "bg-white"} border border-[#CCC] flex items-center justify-center`}><p className="avenir-medium-font text-[19px] text-blue-dark uppercase">{item}</p></div>
            ))}
      </div>
    </div>
  );
};
