import { talentsSelector } from "app/store/models/talents.model";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import { Input } from "components/atoms/Inputs/Inputs";
import { TalentCard } from "components/molecules/Talent/Card";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import TitleSection from "features/Talents/CreateTalent/_components/Title";
import searchInTalents from "helpers/searchInTalents";
import { useState } from "react";
import { useSelector } from "react-redux";
import { TalentsSelectionsList } from "./TalentsSelectionsList";
import AddIcon from "components/atoms/Icons/Add.icon";


export const Hierarchy = ({
  handleChange,
  getValue,
}) => {
  const { talents: globalTalents } = useSelector(talentsSelector);
  const [showTalentsSucceedsForm, setShowTalentsSucceedsForm] = useState(false);
  const [showTalentSuccessorForm, setShowTalentSuccessorForm] = useState(false);
  const [searchSucceeds, setSearchSucceeds] = useState(""); // Depends
  const [searchSuccessor, setSearchSuccessor] = useState(""); // Managed
  const handleSubtmitForSucceedsList = (talentsId) => {
    setShowTalentsSucceedsForm(false);
    handleChange("succeedIds", talentsId);
  }

  const handleSubtmitForSuccessor = (talentId) => {
    setShowTalentSuccessorForm(false);
    handleChange("successorId", talentId);
  }


  return (
    <div>
      <TitleSection>1. Informations personnelles</TitleSection>
      <div>
        <SubTitleSection className="flex items-center gap-2 justify-between w-full">
          <span>Dépend directement de :</span>
          <Input className="border border-gray-light text-xs avenir-light-font w-[200px]" type="search" placeholder="Rechercher" onChange={(e) => setSearchSucceeds(e.target.value)} />
        </SubTitleSection>
        {!showTalentsSucceedsForm &&
          <div className="flex gap-5 overflow-auto xl:w-[calc(100vw-400px-10rem)] xl:max-w-[970px] mb-5">
            {globalTalents.filter((talent) => getValue("succeedIds").includes(talent.id))
              .filter((talent) => searchInTalents(talent, searchSucceeds)).map((talent) => (
                <TalentCard
                  key={talent.id}
                  talent={talent}
                />
              ))}
          </div>
        }
        {showTalentsSucceedsForm && <TalentsSelectionsList
          onSubmit={handleSubtmitForSucceedsList}
          defaultSelected={getValue("succeedIds")}
          onCancel={() => setShowTalentsSucceedsForm(false)}
          search={searchSucceeds}
        />}
        {!showTalentsSucceedsForm && <RoundedButton
          className="!text-blue-dark !border-blue-dark"
          onClick={() => setShowTalentsSucceedsForm(true)}
          icon={<AddIcon />}
        >
          Ajouter un(e) supérieur(e) hiérarchique
        </RoundedButton>}
      </div>
      <hr className="border-0 bg-gray-light h-[1px] my-10" />
      <div>
        <SubTitleSection className="flex items-center gap-2 justify-between w-full">
          <span>DIRIGE OU MANAGE :</span>
          {showTalentSuccessorForm && <Input className="border border-gray-light text-xs avenir-light-font w-[200px]" type="search" placeholder="Rechercher" onChange={(e) => setSearchSuccessor(e.target.value)} />}
        </SubTitleSection>
        {!showTalentSuccessorForm &&
          <div className="flex my-5">
            <TalentCard
              talent={globalTalents.find((talent) => talent.id === getValue("successorId"))}
            />
          </div>
        }
        {showTalentSuccessorForm && <TalentsSelectionsList
          onSubmit={handleSubtmitForSuccessor}
          defaultSelected={getValue("successorId")}
          onCancel={() => setShowTalentSuccessorForm(false)}
          search={searchSuccessor}
          type="radio"
        />}
        {!showTalentSuccessorForm && <RoundedButton
          className="!text-blue-dark !border-blue-dark"
          onClick={() => setShowTalentSuccessorForm(true)}
          icon={<AddIcon />}
        >
          Ajouter un membre d’équipe
        </RoundedButton>}
      </div>
    </div>
  );
};
