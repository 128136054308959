import joinClasses from "helpers/joinClasses";

export function Select({ className, title, children, required, ...props }) {
  return (
    <div className="flex flex-col w-full">
      <p className="text-blue-dark cera-font text-sm mb-2">{title}{title && required && "*"}</p>
      <select
        required={required}
        className={joinClasses("placeholder-gray-light text-gray-darkest border-0 border-b border-gray-light focus:border-blue-dark px-0 pt-2 w-full avenir-light-font transition-all text-sm outline-none", className)} {...props}>
        {children}
      </select>
    </div>
  );
}
