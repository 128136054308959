import { talentsSelector } from "app/store/models/talents.model";
import { InputCheckbox, SmallRoundedButton } from "components/atoms";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import { ConnectedUserTemplate } from "components/templates";
import { Filter } from "features/Home/_components/Filter";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToolbarEditor from "../_components/Toolbar";
import TalentItemList from "./_components/TalentItemList";

export default function TalentsEditor() {
    const navigate = useNavigate();
    const [selectedTalents, setSelectedTalents] = useState([]);
    const { talents } = useSelector(talentsSelector);

    const handleSelectAll = (e) => {
        if (!e.target.checked) {
            setSelectedTalents([]);
            return;
        }
        const allTalents = talents.map((talent) => talent.id);
        setSelectedTalents(allTalents);
    };

    const handleSelect = (e, talentId) => {
        if (!e.target.checked) {
            setSelectedTalents(selectedTalents.filter((id) => id !== talentId));
            return;
        }
        setSelectedTalents([...selectedTalents, talentId]);
    }

    return (
        <ConnectedUserTemplate>
            <ToolbarEditor description={`${talents.length} talents`} />
            {/* Filter & List */}
            <section className="flex w-full flex-col  md:flex-row lg:justify-between xl:gap-24 xl:justify-center">
                <Filter />
                {/* <TalentList data={talents} /> */}
                <div className="md:w-2/3 xl:w-2/4 max-w-[740px] mt-5 px-10 xl:px-0">
                    <div className="flex justify-between items-center mb-2">
                        <div className="pl-8 flex items-center gap-5">
                            <InputCheckbox onChange={handleSelectAll} />
                            {selectedTalents.length ? <SmallRoundedButton className="!border-red text-red hover:bg-red hover:text-white">
                                Supprimer
                            </SmallRoundedButton> : null}
                        </div>
                        <RoundedButton isFill={true}
                            onClick={() => navigate("/editor/talents/create")}
                        >
                            Ajouter
                        </RoundedButton>
                    </div>
                    <ul className="flex flex-col max-h-[calc(100vh-300px)] gap-5 overflow-y-scroll px-8 py-5 bg-white">
                        {talents.map((talent, index) => (
                            <TalentItemList
                                key={index}
                                talent={talent}
                                onSelect={handleSelect}
                                isSelected={selectedTalents.includes(talent.id)}
                            />
                        ))}
                    </ul>
                </div>
            </section> 
        </ConnectedUserTemplate>
    )
}