import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchStats() {
    return axiosRequest({
        method: "GET",
        url: "/talents/stats",
        headers: {
            Authorization: getLocalToken(),
        },
    });
}