import { fetchUniqueTalent } from "api/talents"
import Button from "components/atoms/Buttons/Button"
import Toolbar from "components/molecules/Toolbar"
import { ConnectedUserTemplate } from "components/templates"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import LeftInformations from "./_components/organisms/LeftInformations"
import MainInformations from "./_components/organisms/MainInformations"
import SecondaryInformations from "./_components/organisms/SecondaryInformations"

export const Talent = () => {
    const { id } = useParams();

    const [talent, setTalent] = useState({})

    const getMyTalent = useCallback(async () => {
        const response = await fetchUniqueTalent(id)
        setTalent(response)
    }, [id]);

    useEffect(() => {
        getMyTalent()
    }, [getMyTalent])

    return (
        <ConnectedUserTemplate>
            <Toolbar rightSide={false}>
                <a href={`/editor/talents/create`}>Modifier le talent</a>
            </Toolbar>
            <div className="w-full mt-7 flex justify-center">
                <div className="w-full max-w-[1536px] px-6 2xl:px-0 h-full grid grid-cols-12 gap-16">
                    <LeftInformations talent={talent} />
                    <MainInformations talent={talent} />
                    <SecondaryInformations talent={talent} />
                </div>
            </div >
        </ConnectedUserTemplate >
    )
}