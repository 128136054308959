import AvenirText from "../../../atoms/Texts/AvenirText";
import Template from "./Template";

export function Notes({talent}) {
    return (
        <Template title="Notes" decoration="big" id="notes">
            <div className="flex flex-col gap-4">
                {talent?.Note?.map((item, index) => (
                    <AvenirText key={index} size="sm" weight="medium" className="max-w-lg">{item.content}</AvenirText>
                ))}
            </div>
        </Template>
    )
}