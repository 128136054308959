import { Menu, Triptych } from "./molecules"

export default function LeftInformations({talent}) {


    return (
        <div className="h-full sticky top-20 flex flex-col justify-between gap-10 xl:col-span-2 col-span-3" style={{ height: "calc(100vh - 166px)" }}>
            <Triptych talent={talent} />
            <Menu talent={talent} />
        </div>
    )
}