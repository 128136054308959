import { SmallRoundedButton, SquareCard, Textarea } from "components/atoms";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import { useState } from "react";
import TitleSection from "../../_components/Title";
import { SelectedValue } from "./SelectedValue";
import AddIcon from "components/atoms/Icons/Add.icon";

export const Projects = ({ handleChange, getValue }) => {

  const [description, setDescription] = useState("")
  const [descriptionList, setDescriptionList] = useState([])
  const [showDescription, setShowDescription] = useState(false)
  const [editIndex, setEditIndex] = useState(null)

  // useEffect(() => {
  //   if (getValue("xxx")) {
  //     setComment(getValue("xxx"))
  //   }
  // }, [getValue]);

  const onSubmit = () => {
    if (editIndex !== null) {
      const newList = descriptionList.map((item, index) => {
        if (index === editIndex) {
          return description
        }
        return item
      })
      setDescriptionList(newList)
      setEditIndex(null)
      setDescription("")
      setShowDescription(false)
      return
    }
    setDescriptionList([...descriptionList, description])
    setDescription("")
    // handleChange("xxx", [...descriptionList, description]);
    setShowDescription(false)
  }

  const handleCancel = () => {
    setDescription("")
    setShowDescription(false)
  }

  const handleDelete = (index) => {
    const newList = descriptionList.filter((item, i) => i !== index)
    setDescriptionList(newList)
    // handleChange("xxx", newList);
  }

  const edit = (index) => {
    setEditIndex(index)
    setDescription(descriptionList[index])
    setShowDescription(true)
  }

  return (
    <div>
      <TitleSection>4. Projets et attentes</TitleSection>
      <div className="flex flex-col w-full gap-2.5">
        {descriptionList.map((description, index) => (
          <SelectedValue key={index} onDelete={() => handleDelete(index)} onEdit={() => edit(index)} project={description} />
        ))}
      </div>
      {showDescription ? <SquareCard className="mt-5">
        <Textarea
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          name="comments"
          placeholder="Souhaite partir à l'international..."
          title="Description"
          required={true}
        ></Textarea>
        <div className="flex w-full justify-end mt-5 gap-3">
          <SmallRoundedButton onClick={handleCancel} className="border-gray-light text-gray-light">
            Annuler
          </SmallRoundedButton>
          <SmallRoundedButton onClick={onSubmit} isFill>
            Valider
          </SmallRoundedButton>
        </div>
      </SquareCard> : <RoundedButton className="!text-blue-dark !border-blue-dark mt-5" onClick={() => setShowDescription(true)} icon={<AddIcon />}>Ajouter un projet</RoundedButton>}

    </div>
  );
};