import { fetchEntities } from "api/entities";
import { fetchLanguages } from "api/languages";
import { fetchSkills } from "api/skills";
import { fetchFilteredTalents } from "api/talents";
import { talentsSelector } from "app/store/models/talents.model";
import { SquareCard } from "components/atoms";
import { InputSearch } from "components/atoms/Inputs/InputSearch";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterElement from "./FilterElement";

export const Filter = ({setAllTalents = () => {}}) => {
  const { talents } = useSelector(talentsSelector);

  const [show, setShow] = useState(false);
  const [activeFeeling, setActiveFeeling] = useState(null);

  const [feeling, setFeeling] = useState([
    {
      id: 1,
      title: "Plus de responsabilités",
      value: 1,
      type: "Plus de responsabilités",
      img: "/assets/icons/talent/appreciation/responsability.png",
      alt: "responsability",
    },
    {
      id: 2,
      title: "Plus de mobilité",
      value: 2,
      type: "Plus de mobilité",
      img: "/assets/icons/talent/appreciation/mobility.png",
      alt: "mobility",
    },
    {
      id: 3,
      title: "À suivre de près",
      value: 3,
      type: "À suivre de près",
      img: "/assets/icons/talent/appreciation/follow.png",
      alt: "follow",
    },
  ]);

  const handleFeeling = (value) => {
    if(activeFeeling === value) {
      setActiveFeeling(null);
      return;
    } else {
      setActiveFeeling(value);
    }
  }

  // Gérer les filtres de recherche pour nom/prenom
  const handleSearch = (e) => {
    setFilters((prevState) => ({ ...prevState, search: e.target.value }));
  }

  const [filters, setFilters] = useState({
    ninebox: [],
    entities: [],
    languages: [],
    softSkills: [],
    hardSkills: [],
    search: "",
  })

  const toStringFilters = useCallback(() => {
    let queryString = '?';

    if (filters.entities.length > 0) {
      queryString += `entities=${filters.entities.join(',')}&`;
    }

    if (filters.languages.length > 0) {
      queryString += `languages=${filters.languages.join(',')}&`;
    }

    if (filters.softSkills.length > 0 || filters.hardSkills.length > 0) {
      queryString += `skills=${filters.softSkills.concat(filters.hardSkills).join(',')}&`;
    }

    if (filters.ninebox.length > 0) {
      queryString += `ninebox=${filters.ninebox.join(',')}&`;
    }

    if (filters.search.length > 0) {
      queryString += `search=${filters.search}&`;
    }

    // Remove the last '&' character if present
    if (queryString[queryString.length - 1] === '&') {
      queryString = queryString.slice(0, -1);
    }

    return queryString;
  }, [filters]);


  const [data, setData] = useState([
    {
      title: "Entité / Service",
      type: "entities",
      data: [],
    },
    {
      title: "Ninebox",
      type: "ninebox",
      data: [
        { value: 'A1', label: 'A1' },
        { value: 'A2', label: 'A2' },
        { value: 'A3', label: 'A3' },
        { value: 'B1', label: 'B1' },
        { value: 'B2', label: 'B2' },
        { value: 'B3', label: 'B3' },
        { value: 'C1', label: 'C1' },
        { value: 'C2', label: 'C2' },
        { value: 'C3', label: 'C3' },
      ],
    },
    {
      title: "Langues",
      type: "languages",
      data: [],
    },
    {
      title: "Soft Skills",
      type: "softSkills",
      data: [],
    },
    {
      title: "Hard Skills",
      type: "hardSkills",
      data: [],
    },
  ])

  const handleFetchData = async (fetchTheData, item) => {
    const payload = await fetchTheData();

    setData(prevData => {
      const newData = prevData.map(obj => {
        if (obj.type === item) {
          const transformedData = payload.map(item => ({
            value: item.id,
            label: item.title || item.name,
          }));
          return { ...obj, data: transformedData };
        }
        return obj;
      });
      return newData;
    });
  };

  const getSkills = useCallback(async () => {
    const payload = await fetchSkills();
    const softSkills = payload
      .filter(skill => skill.Type === "SOFT")
      .map(skill => ({ value: skill.id, label: skill.title }));

    const hardSkills = payload
      .filter(skill => skill.Type === "HARD")
      .map(skill => ({ value: skill.id, label: skill.title }));

    setData(prevData => {
      const newData = prevData.map(obj => {
        if (obj.type === "softSkills") {
          return { ...obj, data: softSkills };
        }
        if (obj.type === "hardSkills") {
          return { ...obj, data: hardSkills };
        }
        return obj;
      });
      return newData;
    });
  }, []);

  const getEntities = useCallback(async () => {
    await handleFetchData(fetchEntities, "entities");
  }, []);

  const getLanguages = useCallback(async () => {
    await handleFetchData(fetchLanguages, "languages");
  }, []);

  const fetchFilters = useCallback(async () => {
    const payload = await toStringFilters()
    const response = await fetchFilteredTalents(payload)
    let talentsTab = []
    response?.map(async (talent) => {
      talentsTab.push(talent)
    }
    )
    setAllTalents(talentsTab)
  }, [setAllTalents, toStringFilters])

  useEffect(() => {
    getEntities()
    getLanguages()
    getSkills()
  }, [getEntities, getLanguages, getSkills]);

  useEffect(() => {
    setAllTalents(talents)
  }, [setAllTalents, talents]);

  useEffect(() => {
    fetchFilters()
  }, [filters, fetchFilters]);
  
  useEffect(() => {
    // Ajouter un nouveau délai de 500 ms
    const searchTimeout = setTimeout(() => {
      if (filters?.search) {
        fetchFilters();
      }
    }, 500);
    
    // Annuler le délai précédent lors du nettoyage
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [filters?.search, fetchFilters]);
  
  const [filtersReset, setFiltersReset] = useState(false);

  const handleReset = () => {
    setFilters({
      ninebox: [],
      entities: [],
      languages: [],
      softSkills: [],
      hardSkills: [],
      search: "",
    });
    setFiltersReset(true); // Set filtersReset to true
  };

  useEffect(() => {
    setFiltersReset(false); // Reset filtersReset to false
  }, [filtersReset]);

  const classForMobile = "fixed z-50 w-screen h-[100vh] top-0 ";
  return (
    <>
      <section className={`${show ? "block" : "hidden"} md:block`}>
        <SquareCard
          className={`pb-6 p-5 transition-all md:block md:h-fit md:w-[370px] xl:drop-shadow-2xl xl:-mt-[75px] ${show && classForMobile
            }`}
        >
          <section>
            <span
              className="text-sm font-bold mb-5 text-white border w-6 h-6 rounded-full flex items-center justify-center bg-blue-dark md:hidden"
              onClick={() => setShow(false)}
            >
              X
            </span>
            {/*---------- Title ----------*/}
            <div className="flex justify-between text-xs">
              <h1 className="cera-font font-bold uppercase tracking-widest ">
                Filtres
              </h1>
              <button onClick={handleReset}
                className="underline text-gray-dark cursor-pointer">
                Réinitialiser
              </button>
            </div>
            {/*---------- Search bar ----------*/}
            <InputSearch
              placeholder="Rechercher un talent..."
              className="my-8"
              onChange={handleSearch}
              value={filters?.search}
            />
          </section>
          {/*---------- Filter ----------*/}
          <section>
            <div className="mb-8">
             {data?.map((item, index) => (
                <FilterElement setFilters={setFilters} type={item.type} title={item.title} key={index}
                reset={filtersReset}
                options={item?.data}
                />
                ))} 
            </div >
            {/*---------- Tag ----------*/}
            <div div className="flex flex-col text-sm font-medium gap-5" >
              {feeling.map((item, index) => (
                <div onClick={() => handleFeeling(index)} key={index} className={` cursor-pointer flex items-center gap-5 ${activeFeeling === index ? "opacity-100" : "opacity-40 hover:opacity-100"}`}>
                <img src={item.img} alt={item.alt} className="w-10 h-10" />
                <p className="text-blue-dark">
                  {item.title}
                </p>
              </div>
                ))}
            </div  >
          </section >
        </SquareCard >
      </section >
      <span
        className={`${show ? "hidden" : "fixed"
          } bottom-0 z-50 ml-5 text-sm font-bold mb-5 text-white border px-3 py-2 rounded-full flex items-center justify-center bg-blue-dark md:hidden`}
        onClick={() => setShow(true)}
      >
        Filtres
      </span>
    </>
  );

  
};