import { LineInputText, SmallRoundedButton } from "components/atoms";
import Card from "components/atoms/Cards/Card";
import { useState } from "react";

export const Service = ({ setShowService, service, setService }) => {
  const [serviceLocal, setServiceLocal] = useState(null);

  return (
    <Card className="flex flex-col md:flex-row lg:items-center justify-between gap-5 lg:gap-0">
      <div className="w-full lg:w-1/2">
        <LineInputText
          title="Service ou département"
          placeholder="Ressources Humaines, Finances,"
          onChange={(e) => setServiceLocal(e.target.value)}
          defaultValue={service}
        />
      </div>
      <div className="flex gap-5">
        <SmallRoundedButton
          className="border-gray-light text-gray-light"
          onClick={() => {
            setService(null);
            setShowService(false);
          }}
        >
          Annuler
        </SmallRoundedButton>
        <SmallRoundedButton
          isFill
          onClick={() => {
            setShowService(false);
            setService(serviceLocal);
          }}
        >
          Ajouter
        </SmallRoundedButton>
      </div>
    </Card>
  );
};
