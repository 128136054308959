import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCreateTalent, fetchTalents } from "api/talents";

const initialState = {
  error: "",
  loading: false,
  talents: [],
};

export const getTalents = createAsyncThunk("talents/getAll", async () => {
  const talents = await fetchTalents();
  return talents;
});

export const createTalent = createAsyncThunk(
  "entities/create",
  async (talent) => {
    const response = await fetchCreateTalent(talent);
    return response;
  }
);

export const talentsSlicer = createSlice({
  name: "talents",
  initialState,
  reducers: {
    removeTalent: (state, action) => {
      const index = state.talents.findIndex((item) => item.id === action.payload);
      state.talents.splice(index, 1);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTalent.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(createTalent.fulfilled, (state, action) => {
        state.error = "";
        state.loading = false;
        state.talents.push(action.payload);
      })
      .addCase(createTalent.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });

    builder
      .addCase(getTalents.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(getTalents.fulfilled, (state, action) => {
        state.error = "";
        state.loading = false;
        state.talents = action.payload;

      })
      .addCase(getTalents.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { removeTalent } = talentsSlicer.actions;

export const talentsSelector = (state) => state.talents;

export default talentsSlicer.reducer;
