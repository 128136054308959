import { InputCheckbox } from "components/atoms";
import { IconButton } from "components/atoms/Buttons/IconButton";

export default function TalentItemList({ talent, isSelected, onSelect }) {
    return (
        <li className="w-full flex border-b border-gray-light pb-5">
            <label className="flex items-center justify-between w-full" htmlFor={talent.id}>
                <div className="flex items-center gap-5">
                    <InputCheckbox
                        id={talent.id}
                        name={talent.id}
                        value={talent.id}
                        checked={isSelected}
                        onChange={(e) => onSelect(e, talent.id)}
                    />
                    <img
                        className="w-16 h-16 rounded-full"
                        src={talent?.User?.avatar}
                        alt="avatar"
                    />
                    <p className="avenir-medium-font text-sm text-blue-dark">
                        {talent.User.firstname} {talent.User.lastname}
                    </p>
                </div>
                <p className="text-center avenir-light-font text-sm">
                    {talent.job}
                </p>
                <img src={talent.Entity.avatar} alt="avatar" className="w-10 h-10 rounded-full" />
                <div className="flex gap-4 items-center">
                    <IconButton
                        icon="edit"
                        className="border-blue-dark text-blue-dark"
                    />
                    {/* <IconButton
                        icon="close"
                        className="border-red text-red"
                    /> */}
                </div>
            </label>
        </li>
    );
}