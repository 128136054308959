import TitleSection from "../../_components/Title";
import { Comments } from "./Comments";
import { Feeling } from "./Feeling";
import { Ninebox } from "./NineBox";

export const Evaluations = ({handleChange, getValue }) => {
  
  return (
    <div>      
      <TitleSection>6. Notes et évaluations</TitleSection>
      <Comments handleChange={handleChange} getValue={getValue} />
      <hr className="border-0 bg-gray-light my-[30px] h-[1px]" />
      <Ninebox handleChange={handleChange} getValue={getValue}/>
      <hr className="border-0 bg-gray-light my-[30px] h-[1px]" />
      <Feeling handleChange={handleChange} getValue={getValue}/>
      <p className="avenir-light-font mt-10">Vous pourrez modifier la fiche à tout moment</p>
    </div>
  );
};
