export const menuData = [
    {
        id: 1,
        name: "Compétences",
        href: "#competences",
        active: true
    },
    {
        id: 2,
        name: "Projets & attentes",
        href: "#projets",
        active: false
    },
    {
        id: 3,
        name: "Notes",
        href: "#notes",
        active: false
    },
    {
        id: 4,
        name: "Successions",
        href: "#successions",
        active: false
    },
];

export default menuData;
