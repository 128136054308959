import joinClasses from "helpers/joinClasses";

export default function Toolbar({
  rightSide = false,
  children,
  className,
  ...props
}) {
  return (
    <div
      className={joinClasses(
        "bg-beige-lightest mt-14 xl:mt-0 avenir-light-font w-full",
        rightSide && "flex justify-end"
      )}
      {...props}
      
    >
      <div
        className={joinClasses(
          "flex justify-between items-center py-3 transition-all duration-300 ease-in-out",
          rightSide
            ? "w-full xl:w-3/5 px-8 xl:pr-8"
            : "max-w-screen-xl m-auto px-8",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}