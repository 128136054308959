export const DisplayForm = ({ children, isDisplayed = false }) => {
  return (
    <div
      className={
        isDisplayed ? "block z-0 animate__animated animate__fadeIn" : "hidden"
      }
    >
      {children}
    </div>
  );
};
