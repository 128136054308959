import RoundedButton from "components/atoms/Buttons/RoundedButton";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";
import TitleSection from "../../../_components/Title";
import { FormationForm } from "./form";
import { SelectedValue } from "./SelectedValue";
import AddIcon from "components/atoms/Icons/Add.icon";

export const Training = ({
  handleChange,
  getValue,
}) => {
  const [showFormationForm, setShowFormationForm] = useState(false);
  const [selectedFormation, setSelectedFormation] = useState(null);
  const [formations, setFormations] = useState([]);

  const handleAddFormation = (formation, isEditing = false) => {
    let joinedFormations = [];
    if (isEditing) {
      joinedFormations = formations.map((exp) => {
        if (exp.id === formation.id) return formation;
        return exp;
      });
    } else {
      joinedFormations = [...formations, formation];
    }
    setFormations(joinedFormations);
    setShowFormationForm(false);
    setSelectedFormation(null);
    handleChange("formations", joinedFormations);
  }

  useEffect(() => {
    if (getValue("formations")) setFormations(getValue("formations"));
  }, [formations, getValue]);

  return (
    <div>
      <TitleSection>2. PARCOURS / FORMATIONS</TitleSection>
      <SubTitleSection>Formations professionnelles</SubTitleSection>
      <div className="flex flex-col gap-2 mb-5">
        {formations.sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        }).map((formation, index) => (
          <SelectedValue
            key={index}
            formation={formation}
            onEdit={() => {
              setSelectedFormation(formation);
              setShowFormationForm(true);
            }}
            onDelete={() => {
              const filteredFormations = formations.filter((exp) => exp.id !== formation.id);
              setFormations(filteredFormations);
              handleChange("formations", filteredFormations);
              setSelectedFormation(null);
            }}
          />
        ))}
      </div>
      {showFormationForm && <FormationForm
        onSubmit={handleAddFormation}
        onCancel={() => {
          setShowFormationForm(false)
          setSelectedFormation(null);
        }}
        formation={selectedFormation}
      />}
      {!showFormationForm && <RoundedButton
        className="!text-blue-dark !border-blue-dark"
        onClick={() => setShowFormationForm(true)}
        icon={<AddIcon />}
      >
        Ajouter une formation
      </RoundedButton>}
    </div>
  );
};
