import { InputRadio, LineInputText, SquareCard } from "components/atoms";
import { InputFile } from "components/atoms/Inputs/InputFile";
import CropImage from "components/molecules/CropImage";
import { useEffect, useState } from "react";
import TitleSection from "../../_components/Title";

export const Identity = ({ handleChange, getValue }) => {
  const [avatarBlob, setAvatarBlob] = useState("");
  const [avatar, setAvatar] = useState(null);

  const handleCropImage = (preview, image) => {
    setAvatarBlob(preview);
    setAvatar(image);

    handleChange("avatarBlob", preview);
  }
  useEffect(() => {
    setAvatarBlob(getValue("avatarBlob"));
  }, [getValue])

  return (
    <div>
      <TitleSection>1. Informations personnelles</TitleSection>
      <SquareCard className="flex gap-5 justify-center md:justify-between flex-col-reverse md:flex-row">
        <div className="w-full xl:w-4/6 flex flex-col gap-10">
          <div className="flex flex-col xl:flex-row xl:gap-8">
            <LineInputText
              title="Prénom"
              required
              onChange={(e) => handleChange("firstname", e.target.value)}
              value={getValue("firstname")}
              name="firstname"
              placeholder="Jean"
              type="text"
            />
            <LineInputText
              title="Nom"
              required
              placeholder="Dupont"
              name="lastname"
              onChange={(e) => handleChange("lastname", e.target.value)}
              value={getValue("lastname")}
              type="text"
            />
          </div>
          <div className="flex gap-10">
            <InputRadio
              title="Homme"
              value="Male"
              name="genre"
              onChange={(e) => handleChange("genre", "Male")}
              checked={getValue("genre") === "Male" ? true : false}
            />
            <InputRadio
              title="Femme"
              value="Female"
              name="genre"
              onChange={(e) => handleChange("genre", "Female")}
              checked={getValue("genre") === "Female" ? true : false}
            />
          </div>
          <div className="flex flex-col xl:flex-row xl:gap-8">
            <LineInputText
              title="Date de naissance"
              onChange={(e) => handleChange("birthday", e.target.value)}
              value={getValue("birthday")}
              name="birthday"
              type="date"
            />
            <LineInputText
              title="Numéro de téléphone"
              onChange={(e) => handleChange("phone", e.target.value)}
              value={getValue("phone")}
              name="phone"
              placeholder="06 23 45 67 89"
              pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}"
              type="tel"
            />
          </div>
          <LineInputText
            title="Adresse E-mail"
            onChange={(e) => handleChange("email", e.target.value)}
            value={getValue("email")}
            name="email"
            placeholder="jean@entreprise.com"
            type="email"
          />
          <LineInputText
            title="Adresse"
            onChange={(e) => handleChange("address", e.target.value)}
            value={getValue("address")}
            name="address"
            placeholder="4 Rue de l'entreprise, 88888, Ville"
            type="text"
          />
        </div>
        <div className="h-max w-[1px] bg-black" />
        <div>
          <InputFile
            className="!h-[250px] !w-[250px]"
            name="avatar"
            onChange={(e) => setAvatar(e.target.files[0])}
            preview={avatarBlob}
            accept="image/*"
          />
        </div>
      </SquareCard>
      {avatar ? (
        <CropImage
          setImage={setAvatar}
          setImageBlob={setAvatarBlob}
          onCropComplete={handleCropImage}
          src={avatar}
          ratio={4 / 4}
        />
      ) : (
        ""
      )}
    </div>
  );
};
