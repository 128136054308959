import joinClasses from "helpers/joinClasses";
import { Input } from "./Inputs";

export function LineInputText({
  className,
  title = "",
  required = false,
  ...props
}) {
  return (
    <label className="w-full">
      <span className="text-blue-dark cera-font text-sm">
        {title}
        {title && required && "*"}
      </span>
      <Input
        {...props}
        required={required}
        className={joinClasses(
          className,
          "placeholder-gray-light text-gray-darkest border-0 border-b border-gray-light focus:border-blue-dark px-0 pt-2 w-full avenir-light-font transition-all text-sm outline-none"
        )}
      />
    </label>
  );
}
