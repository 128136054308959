import joinClasses from "helpers/joinClasses";
import { Input } from "./Inputs";

export function InputFile({ className, title, preview = "", ...props }) {
  return (
    <div
      className={joinClasses(
        "w-[200px] h-[200px] relative bg-beige-lightest bg-opacity-50 flex justify-center items-center flex-col bg-center bg-contain bg-no-repeat",
        className
      )}
      style={{
        backgroundImage: `url("${preview && preview.length ? preview : ""}")`,
      }}
    >
      <i className="icon-picture text-blue-dark text-4xl" />
      <p className="text-blue-dark mt-5 text-sm">
        {preview && preview.length ? "Changer la photo" : "Ajouter une photo"}
      </p>
      <Input
        {...props}
        type="file"
        className={joinClasses(
          className,
          "w-[200px] h-[200px] absolute opacity-0"
        )}
      />
      {title}
    </div>
  );
}
