import RoundedButton from "components/atoms/Buttons/RoundedButton";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";
import TitleSection from "../../../_components/Title";
import { StudyForm } from "./form";
import { SelectedValue } from "./SelectedValue";
import AddIcon from "components/atoms/Icons/Add.icon";

export const Studies = ({
  handleChange,
  getValue,
}) => {
  const [showStudyForm, setShowStudyForm] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [studies, setStudies] = useState([]);

  const handleAddStudy = (study, isEditing = false) => {
    let joinedStudies = [];
    if (isEditing) {
      joinedStudies = studies.map((stu) => {
        if (stu.id === study.id) return study;
        return stu;
      });
    } else {
      joinedStudies = [...studies, study];
    }
    setStudies(joinedStudies);
    setShowStudyForm(false);
    setSelectedStudy(null);
    handleChange("studies", joinedStudies);
  }

  useEffect(() => {
    if (getValue("studies")) setStudies(getValue("studies"));
  }, [studies, getValue]);

  return (
    <div>
      <TitleSection>2. PARCOURS / FORMATIONS</TitleSection>
      <SubTitleSection>Diplômes et certifications</SubTitleSection>
      <div className="flex flex-col gap-2 mb-5">
        {studies.sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        }).map((study, index) => (
          <SelectedValue
            key={index}
            study={study}
            onEdit={() => {
              setSelectedStudy(study);
              setShowStudyForm(true);
            }}
            onDelete={() => {
              const filteredStudies = studies.filter((stu) => stu.id !== study.id);
              setStudies(filteredStudies);
              handleChange("studies", filteredStudies);
              setSelectedStudy(null);
            }}
          />
        ))}
      </div>
      {showStudyForm && <StudyForm
        onSubmit={handleAddStudy}
        onCancel={() => {
          setShowStudyForm(false)
          setSelectedStudy(null);
        }}
        study={selectedStudy}
      />}
      {!showStudyForm && <RoundedButton
        className="!text-blue-dark !border-blue-dark"
        onClick={() => setShowStudyForm(true)}
        icon={<AddIcon />}
      >
        Ajouter un diplôme ou une certification
      </RoundedButton>}
    </div>
  );
};
