import SubTitle from "features/Talents/Talent/_components/atoms/Texts/SubTitle";

export default function Template({ children, rightSide, title, decoration, id }) {

    return (
        <div id={id} className={` relative w-full border-b border-gray-darkest mt-8 pb-11`}>
            <SubTitle rightSide={rightSide} title={title} decoration={decoration} />
            {children}
        </div>
    )
}