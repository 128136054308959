import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchLanguages() {
    return axiosRequest({
        method: "GET",
        url: "/languages",
        headers: {
            Authorization: getLocalToken(),
        },
    });
}