import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchSkills() {
  return axiosRequest({
    method: "GET",
    url: "/skills",
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchCreateSkill(data) {
  return axiosRequest({
    method: "POST",
    url: "/skills",
    data: data,
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

