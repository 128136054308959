import joinClasses from "helpers/joinClasses";
import Button from "./Button";

export function SmallRoundedButton({
  children,
  className = "",
  isFill = false,
  ...props
}) {
  return (
    <Button
      className={joinClasses(
        "text-xs transition inline-block text-center rounded-full h-max px-8 py-1.5 border border-white cursor-pointer tracking-wider cera-medium-font",
        isFill
          ? "bg-blue-dark text-white"
          : "bg-transparent  text-white hover:bg-white hover:text-blue-dark",
        className
      )}
      {...props}
    >
      {children}
    </Button>
  );
}
