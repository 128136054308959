import { fetchCreateSkill } from "api/skills"
import { SmallRoundedButton, SquareCard } from "components/atoms"
import { LineInputSearch } from "components/atoms/Inputs/LineInputSearch"
import { useState } from "react"

export const SoftSkillsForm = ({ onSubmit, onCancel, softSkills }) => {

    const [softSkill, setSoftSkill] = useState("")
    const [loading, setLoading] = useState(false)

    const handleCancel = (e) => {
        e.preventDefault()
        onCancel()
    }


    const handleSubmit = async (e) => {
        // si on a un softskill en cours de création
        if (typeof softSkill === "string") {
            setLoading(true)
            console.log("softSkill -> ", softSkill)
            const softskillCreated = await fetchCreateSkill({ title: softSkill.toLowerCase(), Type: "SOFT" })
            console.log("softskillCreated -> ", softskillCreated)
            onSubmit({ name: softSkill, Type: "soft", id: softskillCreated.id })
        } else {
            console.log("selected -> ")
            onSubmit({ name: softSkill.title, Type: "soft", id: softSkill.id })
        }
    }

    return (
        <SquareCard className="flex justify-between items-center ">
            <div className="w-1/2">
                <LineInputSearch
                    placeholder="Rechercher un soft skill"
                    title="Soft skill"
                    list={softSkills.sort((a, b) => a.order < b.order ? -1 : 1)}
                    searchBy="title"
                    onChange={(skill) => setSoftSkill(skill)}
                />
            </div>
            <div className="flex mt-10 gap-3">
                <SmallRoundedButton onClick={handleCancel} className="border-gray-light text-gray-light">
                    Annuler
                </SmallRoundedButton>
                <SmallRoundedButton onClick={handleSubmit} isFill>
                    Ajouter
                </SmallRoundedButton>
            </div>
        </SquareCard>
    )
}