import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchTalents() {
  return axiosRequest({
    method: "GET",
    url: "/talents",
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchCreateTalent(talent) {
  const response = await axiosRequest({
    method: "POST",
    url: "/talents",
    headers: {
      Authorization: getLocalToken(),
    },
    data: talent,
  });

  return response;
}

export async function fetchEditEntity(entity) {
  const response = await axiosRequest({
    method: "PUT",
    url: `/entities/${entity.id}`,
    headers: {
      Authorization: getLocalToken(),
    },
    data: { title: entity.title },
  });

  return response;
}

export async function fetchDeleteEntity(id) {
  const response = await axiosRequest({
    method: "DELETE",
    url: `/entities/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });

  return response;
}

export async function fetchUniqueTalent(id) {
  return axiosRequest({
    method: "GET",
    url: `/talents/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchFilteredTalents(payload){
  return axiosRequest({
    method: "GET",
    url: `/talents/filters${payload}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });
}