import RoundedButton from "components/atoms/Buttons/RoundedButton";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";
import TitleSection from "../../../_components/Title";
import { HardSkillsForm } from "./form";
import { SelectedValue } from "./SelectedValue";
import AddIcon from "components/atoms/Icons/Add.icon";

export const HardSkills = ({ handleChange, getValue }) => {

  // STATES
  const [hardList, setHardList] = useState([
    {
      name: "Powerpoint",
      id: "Powerpoint",
      order: 1,
      type: "hard",
    },
    {
      name: "Photoshop",
      id: "Photoshop",
      order: 2,
      type: "hard"
    },
    {
      name: "Suite Office",
      id: "SuiteOffice",
      order: 3,
      type: "hard"
    },
    {
      name: "Adobe XD",
      id: "AdobeXD",
      order: 4,
      type: "hard"
    },
    {
      name: "Figma",
      id: "Figma",
      order: 5,
      type: "hard"
    },
  ]);
  
  const [dotsSelected, setDotsSelected] = useState(0);
  const [hard, setHard] = useState([]);
  const [addOrEdit, setAddOrEdit] = useState("add");
  const [showHardForm, setShowHardForm] = useState(false);
  const [edit, setEdit] = useState("selected")
  const [refItem, setRefItem] = useState(null)
  const [newHard, setNewHard] = useState({
    name: "",
    percent: 0,
    id: "",
    order: 0,
    type: "hard",
  });

  // FUNCTIONS HELPERS
  const reset = () => {
    setShowHardForm(false)
    setNewHard({
      name: "",
      percent: 0,
      id: "",
      order: 0,
      type: "hard"
    });
  }

  function removeMatchingItems(arr1, arr2) {
    const updatedArr2 = [...arr2];
    arr1.forEach((item1) => {
      const index = updatedArr2.findIndex((item2) => item1.name === item2.name);
      if (index !== -1) {
        updatedArr2.splice(index, 1);
      }
    });
    setHardList(updatedArr2)
    return updatedArr2;
  }

  // FUNCTIONS
  // SHOW FORM
  const displayHardSkillForm = (e) => {
    e.preventDefault();
    setAddOrEdit("add")
    setShowHardForm(!showHardForm);
  };

  // ADD HARD SKILL
  const handleAddHardSkill = (hardSkill) => {
    if (!hardSkill.name || hardSkill.percent < 0 || hardSkill.percent > 5) return;
    const joinedLanguages = [...getValue("skillIds"), hardSkill];
    setHard(joinedLanguages);
    setShowHardForm(false);
    const filteredHardList = hardList.filter((skill) => skill.name !== hardSkill.name);
    setHardList(filteredHardList);
    setNewHard({
      name: "",
      percent: 0,
      id: "",
      order: 0,
      type: "hard"
    });
    setEdit("selected")
    setDotsSelected(0)
    handleChange("skillIds", joinedLanguages);
  }

  // EDIT HARD SKILL
  const handleEditHardSkill = (hardSkill) => {
    if (!hardSkill.name || hardSkill.percent < 0 || hardSkill.percent > 5) return;
    const newArray = [...getValue("skillIds"), hardSkill];
    const index = newArray.findIndex((item) => item.id === refItem.id);
    newArray.splice(index, 1);
    setHard(newArray);
    handleChange("skillIds", newArray);
    setDotsSelected(0)
    setEdit("selected")
    setRefItem(null)
    reset()
  }

  // CANCEL EDIT
  const onEditCancel = () => {
    setEdit("selected")
    setDotsSelected(0)
    const filteredHardList = hardList.filter((item) => item.name !== newHard.name);
    setHardList(filteredHardList);
    reset()
  }

  // REMOVE HARD SKILL
  const removeHardSkill = (index) => {
    const newArray = [...hard];
    const removed = newArray[index];
    newArray.splice(index, 1);
    const joinedHardList = [...hardList, removed];
    joinedHardList.sort((a, b) => a.order - b.order);
    setHardList(joinedHardList);
    setHard(newArray);
    reset()
    handleChange("skillIds", [...getValue("skillIds").filter((item) => item.type === "soft"), ...newArray]);
  }

  // CLICK ON HARD SKILL TO EDIT
  const onEdit = (item) => {
    setAddOrEdit("edit")
    const newList = removeMatchingItems(hard, hardList)
    setShowHardForm(true);
    setNewHard(item);
    setRefItem(item)
    if (!newList.find((hardSkill) => hardSkill.name === item.name)) {
      const joinedHardList = [...newList, item];
      joinedHardList.sort((a, b) => a.order - b.order);
      setHardList(joinedHardList);
      setDotsSelected(joinedHardList.find((hardSkill) => hardSkill.name === item.name).percent - 1);
      setEdit(joinedHardList.findIndex((hardSkill) => hardSkill.id === item.id));
    } else {
      setDotsSelected(newList.find((hardSkill) => hardSkill.name === item.name).percent - 1)
      setEdit(newList.findIndex((hardSkill) => hardSkill.id === item.id));
    }
  }

  useEffect(() => {
    if (getValue("skillIds")) {
      setHard(getValue("skillIds").filter((item) => item.type === "hard"));
      const filteredHardList = hardList.filter((lang) => !getValue("skillIds").find((skill) => skill.name === lang.name && skill.type === lang.type));
      setHardList(filteredHardList);
    }
  }, [getValue]);

  return (
    <div>
      <TitleSection>3. Compétences</TitleSection>
      <SubTitleSection>Hard skills</SubTitleSection>
      <div className="flex items-center mb-5 flex-wrap gap-[25px]">
        {hard.map((item, index) => (
          <SelectedValue
            key={index}
            hard={item}
            onDelete={() => removeHardSkill(index)}
            onEdit={() => onEdit(item)}
          />
        ))}
      </div>
      {showHardForm &&
        <HardSkillsForm
          onCancel={() => {
            addOrEdit === "add" ? reset() : onEditCancel()
          }}
          setNewHard={setNewHard}
          newHard={newHard}
          onSubmit={() => addOrEdit === "add" ? handleAddHardSkill(newHard) : handleEditHardSkill(newHard)}
          hardList={hardList}
          dotsSelected={dotsSelected}
          setDotsSelected={setDotsSelected}
          edit={edit}
          setEdit={setEdit}
        />
      }
      {!showHardForm && <RoundedButton
        className="!text-blue-dark !border-blue-dark"
        onClick={displayHardSkillForm}
        icon={<AddIcon />}
      >
        Ajouter un hard skill
      </RoundedButton>}
    </div>
  );
};