import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchEntities() {
  return axiosRequest({
    method: "GET",
    url: "/entities",
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchCreateEntity(entity) {
  const response = await axiosRequest({
    method: "POST",
    url: "/entities",
    headers: {
      Authorization: getLocalToken(),
    },
    data: { title: entity.title },
  });
  return response;
}

export async function fetchEditEntity(entity) {
  const response = await axiosRequest({
    method: "PUT",
    url: `/entities/${entity.id}`,
    headers: {
      Authorization: getLocalToken(),
    },
    data: { title: entity.title },
  });

  return response;
}

export async function fetchDeleteEntity(id) {
  const response = await axiosRequest({
    method: "DELETE",
    url: `/entities/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });

  return response;
}
