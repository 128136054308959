import joinClasses from "helpers/joinClasses";
import { Input } from "./Inputs";

export function InputSearch({
  className,
  title = "",
  required = false,
  ...props
}) {
  return (
    <label className="">
      <span className="text-blue-dark cera-font text-sm">
        {title}
        {required && "*"}
      </span>
      <Input
        {...props}
        className={joinClasses(
          className,
          "placeholder-gray-light text-gray-darkest border border-blue-dark p-2 focus:bg-gray-lightest w-full avenir-light-font transition-all text-sm outline-none"
        )}
      />
    </label>
  );
}
