import SmallTitle from "../../../atoms/Texts/SmallTitle";
import SubTitle from "../../../atoms/Texts/SubTitle";

export function Formations({talent}) {
    return (
        <div className="border-y-[#CCC] border-y flex flex-col items-start gap-4 py-8 w-full mt-4 mb-7">
                <SubTitle decoration={"small"} title="Formations pro" />
                <div>
                    {talent?.Formation?.map((item, index) => (
                        <SmallTitle key={index} >{new Date(item.date).getFullYear()} : {item.title}</SmallTitle>
                    ))}
                </div>
            </div>
    )
}