import AvenirText from "../../../atoms/Texts/AvenirText";
import Template from "./Template";

export function Projects({talent}) {
    return (
        <Template title="Projets et attentes" id="projets" decoration="big">
            <div className="flex flex-col gap-8">
                {talent?.Project?.map((item, index) => (
                    <div key={index} className="flex items-start gap-4">
                        <div className="w-[18px] rounded-full aspect-square bg-white border border-gray-dark"></div>
                        <AvenirText size="sm" weight="medium" color="gray-dark" className="max-w-sm">{item.content}</AvenirText>
                    </div>
                ))}
            </div>
        </Template>
    )
}