import { SmallRoundedButton, SquareCard, Textarea } from "components/atoms";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";


export const Comments = ({ handleChange, getValue }) => {

  const [comment, setComment] = useState("")
  const [showComment, setShowComment] = useState(false)

  useEffect(() => {
    if (getValue("note")) {
      setComment(getValue("note"))
    }
  }, [getValue]);

  const onSubmit = () => {
    handleChange("note", comment);
    setShowComment(false)
  }

  const handleCancel = () => {
    setShowComment(false)
    if (getValue("note")) {
      setComment(getValue("note"))
    }
  }

  return (
    <div>
      <SubTitleSection>Commentaires</SubTitleSection>
      {!showComment && comment && <p className="cera-font mb-5">{comment}</p>}
      {showComment ? <SquareCard>
        <Textarea
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          name="comments"
          placeholder="Se sent pleinement intégré, éprouve des difficultés,…"
          title="Ajoutez une appréciation, une note, ou un commentaire"
          required={true}
        ></Textarea>
        <div className="flex w-full justify-end mt-5 gap-3">
          <SmallRoundedButton onClick={handleCancel} className="border-gray-light text-gray-light">
            Annuler
          </SmallRoundedButton>
          <SmallRoundedButton onClick={onSubmit} isFill>
            Ajouter
          </SmallRoundedButton>
        </div>
      </SquareCard> : <SmallRoundedButton onClick={() => setShowComment(true)} isFill>{comment ? "Modifier le commentaire" : "Ajouter un commentaire"}</SmallRoundedButton>}
    </div>
  );
};
