import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";
export const Feeling = ({ handleChange, getValue }) => {

  const follow = "/assets/icons/talent/appreciation/follow.png"
  const mobility = "/assets/icons/talent/appreciation/mobility.png"
  const responsability = "/assets/icons/talent/appreciation/responsability.png"

  const [feelings] = useState([
    {
      id: 1,
      title: "Plus de responsabilités",
      value: 1,
      type: "Plus de responsabilités",
      img: responsability
    },
    {
      id: 2,
      title: "Plus de mobilité",
      value: 2,
      type: "Plus de mobilité",
      img: mobility
    },
    {
      id: 3,
      title: "À suivre de près",
      value: 3,
      type: "À suivre de près",
      img: follow
    }
  ])
  const [selectedFeeling, setSelectedFeeling] = useState(null)

  const handleFeeling = (e) => {
    setSelectedFeeling(feelings[e.target.value - 1])
    handleChange("label", feelings[e.target.value - 1])
  }

  useEffect(() => {
    if (getValue("label")) {
      setSelectedFeeling(getValue("label"))
    }
  }, [getValue]);


  return (
    <div>
      <SubTitleSection>Ressenti</SubTitleSection>
      <p className="cera-light-font text-blue-dark mb-6">Comment se sent le profil à son poste ?</p>
      <div className="relative w-[340px]">
        <div className={`w-10 h-10 top-[7.5px] left-[7.5px] absolute ${!selectedFeeling && "border border-[#7C7C7C]"} rounded-full`}>
          {selectedFeeling?.img && <img src={selectedFeeling?.img} alt={selectedFeeling?.title} />}

        </div>
        <select onChange={handleFeeling} className="rounded-full w-full h-[55px] pl-[67.5px]">
          <option>Choisir un ressenti</option>
          {feelings.map((item) => (
            <option key={item.id} value={item.value}>{item.title}</option>
          ))}
        </select>
      </div>
    </div>
  );
};