import { InputCheckbox, LineInputText, SmallRoundedButton, Textarea } from "components/atoms";
import Card from "components/atoms/Cards/Card";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const ExperienceForm = ({ onSubmit, onCancel, experience }) => {
    const [experienceLocal, setExperienceLocal] = useState({
        id: Date.now(),
        title: "",
        description: "",
        beginning: null,
        ending: null,
        isActual: false,
    });

    useEffect(() => {
        if (experience) setExperienceLocal(experience);
    }, [experience]);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (dayjs(experienceLocal.ending).isAfter(dayjs())) {
            toast.error("La date de fin ne peut pas être supérieure à la date du jour");
            return;
        }
        if (dayjs(experienceLocal.ending).isBefore(dayjs(experienceLocal.beginning))) {
            toast.error("La date de fin ne peut pas être antérieure à la date de début");
            return;
        }
        onSubmit(experienceLocal, experience ? true : false);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    return (
        <form onSubmit={handleSubmit}>
            <Card className="flex flex-col items-center justify-between gap-5">
                <div className="flex flex-col lg:flex-row gap-10 w-full">
                    <div className="w-full lg:w-1/2">
                        <p className="text-blue-dark cera-font text-sm">Date</p>
                        <div className="flex items-center gap-2">
                            <LineInputText
                                onChange={(e) => setExperienceLocal({ ...experienceLocal, beginning: e.target.value })}
                                value={experienceLocal.beginning}
                                name="beginning"
                                type="date"
                                required={true}
                                max={dayjs().format("YYYY-MM-DD")}
                            />
                            <p className="text-blue-dark cera-font text-sm">au</p>
                            <div className="w-full relative">
                                {experienceLocal.isActual && <div className="w-full h-full cursor-not-allowed bg-gray-light opacity-75 absolute z-10" />}
                                <LineInputText
                                    onChange={(e) => {
                                        if (experienceLocal.isActual) return;
                                        setExperienceLocal({ ...experienceLocal, ending: e.target.value })
                                    }}
                                    value={experienceLocal.ending}
                                    name="beginning"
                                    type="date"
                                    className={experienceLocal.isActual ? "" : ""}
                                    required={true}
                                    max={dayjs().format("YYYY-MM-DD")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2">
                        <LineInputText
                            onChange={(e) => setExperienceLocal({ ...experienceLocal, title: e.target.value })}
                            value={experienceLocal.title}
                            name="title"
                            placeholder="Directeur de gestion"
                            title="Intitulé du poste"
                            className="mt-1"
                            required={true}
                        />
                    </div>
                </div>
                <div className="flex justify-start w-full">
                    <InputCheckbox name="isActual" title="Poste actuel"
                        onChange={(e) => {
                            if (e.target.checked) setExperienceLocal({ ...experienceLocal, ending: dayjs().format("YYYY-MM-DD"), isActual: true })
                            else setExperienceLocal({ ...experienceLocal, isActual: false })
                        }}
                    />
                </div>
                <div className="flex justify-start w-full">
                    <Textarea
                        onChange={(e) => setExperienceLocal({ ...experienceLocal, description: e.target.value })}
                        value={experienceLocal.description}
                        name="description"
                        placeholder="Stage pratique dans ..."
                        title="Description"
                        required={true}
                    ></Textarea>
                </div>
                <div className="flex w-full gap-5">
                    <SmallRoundedButton className="border-gray-light text-gray-light" onClick={handleCancel}>
                        Annuler
                    </SmallRoundedButton>
                    <SmallRoundedButton isFill type="submit">
                        {experience ? "Modifier" : "Ajouter"}
                    </SmallRoundedButton>
                </div>
            </Card>
        </form>
    );
};
