import { Icon } from "components/atoms";
import Toolbar from "components/molecules/Toolbar";
import { ConnectedUserTemplate } from "components/templates";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultTalentJSON from "utils/talent-state-template.json";
import { breadcrumb } from "./_utils";
import { Breadcrumb } from "components/molecules";
import { DisplayForm } from "./_components/DisplayForm";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import { fetchCreateTalent } from "api/talents";
import { toast } from "react-toastify";

export const CreateTalent = () => {

  const { defaultTalent } = defaultTalentJSON;
  const formatTalentJSONToState = (talent) => {
    let talentState = {};
    for (const [key, value] of Object.entries(talent)) {
      if (value.value) {
        talentState[key] = value.value;
      } else if (value.type === "array") {
        talentState[key] = [];
      } else if (value.type === "object") {
        talentState[key] = {};
      } else if (value.type === "string") {
        talentState[key] = "";
      } else if (value.type === "number") {
        talentState[key] = 0;
      } else if (value.type === "boolean") {
        talentState[key] = false;
      } else {
        talentState[key] = null;
      }
    }
    return talentState;
  }

  const [talent, setTalent] = useState(formatTalentJSONToState(defaultTalent));
  const [activeSection, setActiveSection] = useState({ id: 1, subId: 1 });

  let navigate = useNavigate();

  const handleChange = (fieldName, value) => {
    const newTalent = {
      ...talent,
      [fieldName]: value,
    }

    setTalent(newTalent);
    localStorage.setItem("talent", JSON.stringify(newTalent));
  }
  const getValue = (fieldName) => {
    return talent[fieldName];
  }

  const exit = () => {
    if (window.confirm("Voulez-vous vraiment quitter la création de talent, toutes les données saisies seront perdues ?")) {
      localStorage.removeItem("talent");
      navigate("/editor/talents");
    }
  }

  useEffect(() => {
    console.log(talent);
  }, [talent]);

  useEffect(() => {
    const talent = localStorage.getItem("talent");
    if (talent) setTalent(JSON.parse(talent));
  }, []);

  const nextStep = async (e) => {
    e.preventDefault();

    const currentSection = breadcrumb.find(
      (section) => section.id === activeSection.id
    );
    if (currentSection.sections.length > activeSection.subId) {
      setActiveSection({
        ...activeSection,
        subId: activeSection.subId + 1,
      });
    } else {
      if (breadcrumb[currentSection.id].sections.length > 0) {
        setActiveSection({
          id: activeSection.id + 1,
          subId: 1,
        });
      } else {
        setActiveSection({
          id: activeSection.id + 1,
          subId: 0,
        });
      }
    }
  };

  const checkIsLastStep = () => {
    const currentSection = breadcrumb.find(
      (section) => section.id === activeSection.id
    );
    return (
      breadcrumb.length === activeSection.id &&
      currentSection.sections.length === activeSection.subId
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await fetchCreateTalent({
        ...talent,

        avatarBlob: null,
      });
      console.log(result);
    } catch (error) {
      toast.error(error.message);
    }
  }


  return (
    <ConnectedUserTemplate>
      <Toolbar className="xl:max-w-screen-2xl xl:gap-20">
        <div className="hidden xl:block w-[620px] h-1" />
        <div className="w-full flex justify-between">
          <button
            className="flex items-center gap-2"
            onClick={() => exit()}
          >
            <Icon name="arrow-light-left" /> <p className="mt-0.5">Retour</p>
          </button>
          <div className="flex items-center gap-4 text-gray-darkest">
            <p>
              {getValue("firstname") || "Prénom"}{" "}
              {getValue("lastname") || "NOM"}
            </p>
            <img
              src={getValue("avatarBlob")}
              alt=""
              className="w-[46px] h-[46px] rounded-full object-cover bg-black bg-opacity-20 overflow-hidden border-0"
            />
          </div>
        </div>
      </Toolbar>


      <div className="flex flex-col xl:flex-row gap-5 xl:gap-20 lg:px-10 xl:max-w-screen-2xl m-auto">
        <Breadcrumb
          title="Création fiche talent"
          className="-translate-y-0 xl:-translate-y-20"
          data={breadcrumb}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
        <div className="w-full xl:mt-3 px-3 lg:px-0">
          {breadcrumb.map((section, index) => {
            if (section.indexComponent)
              return (
                <DisplayForm
                  key={index}
                  isDisplayed={
                    activeSection.id === section.id && activeSection.subId === 0
                  }
                >
                  <section.indexComponent handleChange={handleChange} getValue={getValue} />
                </DisplayForm>
              );
            else
              return section.sections.map((subSection, subIndex) => (
                <DisplayForm
                  key={subIndex}
                  isDisplayed={
                    section.id === activeSection.id &&
                    activeSection.subId === subSection.id
                  }
                >
                  <subSection.component handleChange={handleChange} getValue={getValue} />
                </DisplayForm>
              ));
          })}
          <div className="my-10">
            <hr className="border-0 bg-gray-light h-[1px]" />
            {checkIsLastStep() ? (
              <RoundedButton isFill className="mt-6" onClick={handleSubmit}>
                Terminer
              </RoundedButton>
            ) : (
              <RoundedButton isFill className="mt-6" onClick={nextStep}>
                Valider et continuer
              </RoundedButton>
            )}
          </div>
        </div>
      </div>
    </ConnectedUserTemplate>
  );
};