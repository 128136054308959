import Template from "./Template";
import { TalentCard } from "./TalentCard";
import { useEffect, useState } from "react";

export function Successions({ talent }) {
  const [succession, setSuccession] = useState("succeed");
  const [talentsMap, setTalentsMap] = useState([]);
  console.log(talent);
  useEffect(() => {
    if (succession === "succeed") {
      let temp = [];
      talent?.Succeed?.forEach((talentData) => {
        temp.push(talentData?.Talent);
      });
      setTalentsMap(temp);
    } else if (succession === "successor") {
      setTalentsMap(talent?.Successor?.TalentList || []);
    }
  }, [talent, succession]);

  return (
    <Template title="Successions" id="successions" decoration="big">
      <div className="absolute left-0 -translate-y-[115%]">
        <select
          className="w-[300px] h-11 border border-blue-dark rounded-full bg-gray-lightest"
          onChange={() => {
            setSuccession(succession === "succeed" ? "successor" : "succeed");
          }}
        >
          <option value={"succeed"} className="uppercase text-blue-dark">
            Peut succéder à
          </option>
          <option value={"successor"} className="uppercase text-blue-dark">
            A comme successeurs :
          </option>
        </select>
      </div>
      <div className="w-full mt-16 grid grid-cols-3 gap-[100px]">
        {talentsMap.map((talent, index) => (
          <TalentCard key={index} talent={talent} />
        ))}
      </div>
    </Template>
  );
}
