import { Icon } from "components/atoms";
import Button from "components/atoms/Buttons/Button";
import RoundedButton from "components/atoms/Buttons/RoundedButton";
import SubTitleSection from "features/Talents/CreateTalent/_components/SubTitle";
import { useEffect, useState } from "react";
import TitleSection from "../../../_components/Title";
import { LanguagesForm } from "./form";
import AddIcon from "components/atoms/Icons/Add.icon";

export const Languages = ({ handleChange, getValue }) => {

  const [languages, setLanguages] = useState([]);
  const [languagesList, setLanguagesList] = useState([
    {
      name: "Français",
      code: "fr",
      order: 1
    },
    {
      name: "Allemand",
      code: "de" ,
      order: 4
    },
    {
      name: "Anglais",
      code: "gb",
      order: 2
    },
    {
      name: "Espagnol",
      code: "es",
      order: 3
    },
    {
      name: "Italien",
      code: "it",
      order: 5
    },
  ]);

  const [showLanguageForm, setShowLanguageForm] = useState(false);

  const [newLanguage, setNewLanguage] = useState({
    name: "",
    code: "",
    level: 0,
    order: 0,
  });

  const displayLanguageForm = (e) => {
    e.preventDefault();
    setShowLanguageForm(!showLanguageForm);
  };

  const handleAddLanguage = (language) => {
    if (!language.name || !language.code || language.level < 0 || language.level > 5) return;
    const joinedLanguages = [...languages, language];
    setLanguages(joinedLanguages);
    setShowLanguageForm(false);
    const filteredLanguagesList = languagesList.filter((lang) => lang.code !== language.code);
    setLanguagesList(filteredLanguagesList);
    setNewLanguage({
      name: "",
      code: "",
      level: 0,
    });
    handleChange("language", joinedLanguages);
  }

  useEffect(() => {
    if (getValue("language")) {
      setLanguages(getValue("language"));
      // remove for languageList , language that are in languages
      const filteredLanguagesList = languagesList.filter((lang) => !getValue("language").find((language) => language.code === lang.code));
      setLanguagesList(filteredLanguagesList);
    }

  }, [languages, getValue]);

  const removeLanguage = (index) => {
    const newArray = [...languages];
    const removed = newArray[index];
    newArray.splice(index, 1);
    const joinedLanguagesList = [...languagesList, removed];
    joinedLanguagesList.sort((a, b) => a.order - b.order);
    setLanguagesList(joinedLanguagesList);
    setLanguages(newArray);
    handleChange("language", newArray);
  }



  return (
    <div>
      <TitleSection>3. Compétences</TitleSection>
      <SubTitleSection>Langues</SubTitleSection>
      <div className="flex items-center mb-5 flex-wrap gap-[25px]">
        {languages.map((language, index) => (
          <div key={index} className="max-w-[243px] w-full pl-[14px] border border-[#E0E0E0] pr-[17px] flex h-[51px] justify-between bg-[#ECECEC] items-center">
            <div className="flex items-center gap-2.5">
              <span className={`fi-${language.code} rounded-full h-7 w-7 bg-center bg-cover bg-no-repeat `}></span>
              <p className="cera-font text-sm text-gray-dark">{language.name}</p>
            </div>
            <Button
              className="h-[30px] w-[30px] border border-red rounded-full text-sm text-red flex justify-center items-center"
              onClick={() => removeLanguage(index)}
            >
              <Icon name="close" />
            </Button>
          </div>
        ))}
      </div>
      {showLanguageForm &&
        <LanguagesForm
          onCancel={() => {
            setShowLanguageForm(false)
            setNewLanguage({
              name: "",
              code: "",
              level: 0,
            });
          }}
          setNewLanguage={setNewLanguage}
          newLanguage={newLanguage}
          onSubmit={() => handleAddLanguage(newLanguage)}
          languagesList={languagesList}
        />
      }
      {!showLanguageForm && <RoundedButton
        className="!text-blue-dark !border-blue-dark"
        onClick={displayLanguageForm}
        icon={<AddIcon />}
      >
        Ajouter une langue
      </RoundedButton>}
    </div>
  );
};