import Arrow from "components/atoms/Icons/Arrow.icon"
import BigArrow from "components/atoms/Icons/bigArrow.icon"
import menuData from "features/Talents/Talent/data/menu"
import { useState } from "react"
import AvenirText from "../../../atoms/Texts/AvenirText"

export function Menu({ talent }) {

    const [menu, setMenu] = useState(menuData)

    const handleActiveMenu = (id) => {
        const newMenu = menu.map(item => {
            if (item.id === id) {
                item.active = true
            } else {
                item.active = false
            }
            return item
        })
        setMenu(newMenu)
    }


    return (
        <ul className="flex flex-col w-full">
            {menu?.map((item, index) => (
                <li key={index} onClick={() => {
                    handleActiveMenu(item.id)
                }} className={`uppercase text-left flex items-center pt-2  avenir-black-font`}>
                    <a href={item.href} className={`flex items-center text-xs ${item.active ? "text-blue-dark" : "text-[#B1B1B1]"} hover:text-blue-dark gap-[14px]`}>
                        {item.active ? <BigArrow /> : <Arrow />}
                        <AvenirText color={item.active ? "blue-dark" : "[#B1B1B1]"} weight="black" size="xs" className="hover:text-blue-dark tracking-widest">
                            {item.name}
                        </AvenirText>
                    </a>
                </li>
            ))}
        </ul>
    )
}