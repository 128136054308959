export const talentData = [
    {
        id: 1,
        name: "Jean-Sébastien Gomez",
        job: "Directeur de Création",
        enterprise: "Agence RJS",
        age: "24",
        location: "Ile-de-France",
        avatar: "https://api.dicebear.com/6.x/personas/svg?backgroundColor=b6e3f4,c0aede,d1d4f9",
        ninebox: "A1",
        entity: "Nom de l'entité",
        service: "Nom du Service",
        appreciation : {
            src : "/assets/icons/talent/appreciation/responsability.png",
            alt: "responsability",
            content: "Souhaite plus de responsabilités",
            date: "06/07/2021"
        },
        hardSkills : [
            {
                percent : "60%",
                name: "Indesign",
            },
            {
                percent : "40%",
                name: "Photoshop",
            },
            {
                percent : "24%",
                name: "Illustrator",
            },
            {
                percent : "95%",
                name: "Adobe XD",
            },
        ],
        softSkills : [
            {
                name: "Bienveillance",
            },
            {
                name: "Communication",
            },
            {
                name: "Marketing",
            },
            {
                name: "Gestion du site",
            },
        ],
    },
];

export default talentData;
