import { configureStore } from "@reduxjs/toolkit";

// Middlewares
import errorMiddleware from "./middlewares/errorMiddleware";

// Reducers
import userReducer from "./models/user.model";
import entitiesReducer from "./models/entities.model";
import talentsReducer from "./models/talents.model";

export const store = configureStore({
  reducer: {
    user: userReducer,
    entities: entitiesReducer,
    talents: talentsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorMiddleware),
});

