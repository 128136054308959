import Data from "components/atoms/Icons/Data.icon";
import AvenirText from "../../../atoms/Texts/AvenirText";
import SmallTitle from "../../../atoms/Texts/SmallTitle";
import Template from "./Template";

export function Skills({ talent }) {

    return (
        <Template rightSide={<RightItem />} title="Compétences" id="competences" decoration="big" >
            <div className="w-full grid grid-cols-8 items-start gap-5">
                <div className="col-span-5  flex flex-col items-start">
                    <div className="mb-2">
                        <h3 className={`text-sm avenir-medium-font text-blue-dark uppercase`}>HARD SKILLS</h3>
                        <div className="h-0.5 bg-gray-darkest w-5 mt-2 " />
                    </div>
                    <ul className="w-full flex flex-col gap-0.5">
                        {/* map all skills in talent.Skill where Type === HARd 
        
        */}
                        {talent?.Skill?.filter(skill => skill.Type === 'HARD')?.map((skill, index) => (
                            <li key={index} className="w-full flex items-center gap-3" >
                                <div className="flex rounded-full h-2 w-2/5 bg-gray-dark/[0.13]">
                                    <div className="h-full bg-blue-dark rounded-full" style={{ width: `${skill.percent}%` }}></div>
                                </div>
                                <AvenirText size="sm" weight="light" color="gray-dark" className="min-w-max">{skill.title}</AvenirText>
                            </li>
                        ))}
                    </ul>
                    <button className="underline mt-5 ml-1.5 avenir-light-font text-sm text-gray-dark uppercase">VOIR +</button>
                </div>
                <div className="col-span-3 flex flex-col gap-3 border-l border-l-gray-dark pl-3.5 pb-4 pt-2">
                    <div>
                        <h3 className={`text-sm avenir-medium-font text-blue-dark uppercase`}>SOFT SKILLS</h3>
                        <div className="h-0.5 bg-gray-darkest w-5 mt-2 " />
                    </div>
                    <ul className="list-disc pl-2.5">
                        {talent?.Skill?.filter(skill => skill.Type === "SOFT")?.map((skill, index) => (
                            <li key={index} className="text-sm text-gray-dark avenir-medium-font">
                                {skill.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Template>
    )
}

export function RightItem() {
    return (
        <div className="flex items-center text-blue-dark gap-4">
            <SmallTitle className="underline text-[10px]">DATAS</SmallTitle>
            <Data />
        </div>
    )
}