import { axiosRequest } from "helpers/axios";

export async function fetchLogin(user) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/login",
    data: {
      ...user,
    },
  });
}

export async function fetchRegisterEmail(email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/email/register",
    data: {
      email: email,
    },
  });
}

export async function fetchRegister(user) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/user/register",
    data: {
      ...user,
    },
  });
}

export async function fetchVerifyEmail(code, email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/email/verify",
    data: {
      email: email,
      code: Number(code),
    },
  });
}

export async function fetchRegisterIp(code, email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/ip/register",
    data: {
      email: email,
      code: Number(code),
    },
  });
}

export async function fetchCheckToken(token) {
  return await axiosRequest({
    method: "GET",
    url: "/authentication/user/auth",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function fetchUserInfo(token) {
  return await axiosRequest({
    method: "GET",
    url: "/users/me",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
