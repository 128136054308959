import { Route, BrowserRouter, Routes } from "react-router-dom";
import Login from "../features/Auth/Login/login.page";
import Register from "../features/Auth/Register/register.page";

export default function AuthenticationRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/register" element={<Register />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}
