import { Icon } from "components/atoms";
import Button from "components/atoms/Buttons/Button";
import dayjs from "dayjs";
import STUDY_LEVELS from "utils/study-level.json";

export const SelectedValue = ({
  onEdit,
  onDelete,
  study,
}) => {
  const studyLevel = STUDY_LEVELS.find((studyLevel) => studyLevel.level === study.level)
  return (
    <div
      className="flex items-center w-full border border-gray-light px-5"
      style={{ backgroundColor: "#ECECEC" }}
    >
      <div className="flex flex-col w-full py-5 gap-1">
        <p className="cera-medium-font text-gray-dark -mt-1">{dayjs(study.date).format("YYYY")} - {study.title}</p>
        <p className="cera-medium-font text-gray-dark -mt-1 text-sm">
          {studyLevel.level} (Certification de niveau {studyLevel.level_certification})
        </p>
      </div>
      <div className="flex gap-5">
        <Button
          className="h-[30px] w-[30px] border border-blue-dark rounded-full text-sm text-blue-dark flex justify-center items-center"
          onClick={onEdit}
        >
          <Icon name="edit" />
        </Button>
        <Button
          className="h-[30px] w-[30px] border border-red rounded-full text-sm text-red flex justify-center items-center"
          onClick={onDelete}
        >
          <Icon name="close" />
        </Button>
      </div>
    </div>
  );
};
