import SmallTitle from "../../../atoms/Texts/SmallTitle";
import SubTitle from "../../../atoms/Texts/SubTitle";

export function Studies({talent}) {
    return (
        <div className="flex flex-col items-start gap-4 w-full mb-7">
            <SubTitle decoration={"small"} title="Études" />
            <div className="flex flex-col">
                {talent?.Study?.map((item, index) => (
                    <SmallTitle key={index} >{new Date(item.date).getFullYear()} : {item.level} {item.complement && <span className="!text-gray-dark avenir-medium-font">{item.complement}</span>}</SmallTitle>
                ))}
            </div>
        </div>
    )
}