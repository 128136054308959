import { LineInputText, Select, SmallRoundedButton } from "components/atoms";
import Card from "components/atoms/Cards/Card";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import STUDY_LEVELS from "utils/study-level.json";
export const StudyForm = ({ onSubmit, onCancel, study }) => {
    const studyLevels = STUDY_LEVELS;
    const [studyLocal, setStudyLocal] = useState({
        id: Date.now(),
        title: "",
        date: dayjs().format("YYYY"),
        level: "",
    });

    useEffect(() => {
        if (study) setStudyLocal(study);
    }, [study]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(studyLocal, study ? true : false);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    return (
        <form onSubmit={handleSubmit}>
            <Card className="flex flex-col items-center justify-between gap-5">
                <div className="flex flex-col lg:flex-row gap-10 w-full">
                    <div className="w-full lg:w-1/3">

                        <div className="flex items-center gap-2">
                            <Select
                                onChange={(e) => {
                                    const date = dayjs(e.target.value).format("YYYY-MM-DD");
                                    setStudyLocal({ ...studyLocal, date })
                                }}
                                required={true}
                                title="Année"
                                value={dayjs(studyLocal.date).format("YYYY")}
                            >
                                {[...Array(100)].map((_, i) => (
                                    <option value={dayjs().subtract(i, "year").format("YYYY")}>
                                        {dayjs().subtract(i, "year").format("YYYY")}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="w-full lg:w-2/3">
                        <LineInputText
                            onChange={(e) => setStudyLocal({ ...studyLocal, title: e.target.value })}
                            value={studyLocal.title}
                            name="title"
                            placeholder="Management et finances - HEC Paris"
                            title="Intitulé du diplôme ou de la certification"
                            className="py-3"
                            required={true}
                        />
                    </div>
                </div>
                <div className="w-full">


                    <Select
                        onChange={(e) => setStudyLocal({ ...studyLocal, level: e.target.value })}
                        required={true}
                        value={studyLocal.level}
                        title="Niveau de certification ou de scolarité"
                    >
                        {studyLevels.map((studyLevel, i) => (
                            <option value={studyLevel.level}>
                                {studyLevel.level} (Certification de niveau {studyLevel.level_certification})
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="flex w-full gap-5">
                    <SmallRoundedButton className="border-gray-light text-gray-light" onClick={handleCancel}>
                        Annuler
                    </SmallRoundedButton>
                    <SmallRoundedButton isFill type="submit">
                        {study ? "Modifier" : "Ajouter"}
                    </SmallRoundedButton>
                </div>
            </Card>
        </form>
    );
};
