import React, { useEffect, useRef, useState } from 'react';

const FilterElement = ({ type, setFilters, title, options = [], reset }) => {
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  
  const wrapperRef = useRef(null);

  // gere le click sur les checkbox
  const handleOptionChange = (option) => {
    if (selected.includes(option)) {
      const newSelected = selected.filter((item) => item !== option);
      setSelected(newSelected);
      setFilters((prevState) => ({
        ...prevState,
        [type]: prevState[type].filter((item) => item !== option),
      }));
    } else {
      const newSelected = [...selected, option];
      setSelected(newSelected);
      setFilters((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], option],
      }));
    }
  };

  useEffect(() => {
    setSelected([]);
  }, [reset]);
  
  // gere le changement de la barre de recherche
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // ferme le filtre si on clique autre part
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // ferme le filtre si on clique autre part
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block w-full border-b border-gray-soft pb-2 mb-2" ref={wrapperRef}>
      <h2 className="text-blue-dark font-bold text-xs">{title}</h2>
      <button onClick={() => { setIsOpen(!isOpen) }} className="w-full bg-white flex justify-between items-center rounded px-4 mt-2 py-2 text-gray-700 focus:outline-none">
        <p>{selected.length === 0 ? 'Sélectionner des options' : `${selected.length} options sélectionnées`}</p>
        <p className='rotate-180'>^</p>
      </button>
      {isOpen && <div className="absolute z-10 max-h-[250px] overflow-auto w-full mt-2 bg-white  rounded border rounded-md">
        <input
          type="text"
          className="w-full px-4 py-2 border-b border-x-white border-t-white rounded-t-md focus:outline-none"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {options?.filter((option) =>
          option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
        )?.map((option, index) => (
          <div
            key={index}
            className="flex items-center px-4 py-2  hover:bg-gray-100 cursor-pointer"
            onClick={() => handleOptionChange(option.value)}
          >

            <input
              type="checkbox"
              className="form-checkbox mr-2"
              checked={selected.includes(option.value)}
              onChange={() => handleOptionChange(option.value)}
            />
            <span>{option?.label}</span>
          </div>
        ))}
      </div>}
    </div>
  );
};

export default FilterElement;