import { Select, SmallRoundedButton, Textarea } from "components/atoms";
import Card from "components/atoms/Cards/Card";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export const FormationForm = ({ onSubmit, onCancel, formation }) => {
    const [formationLocal, setFormationLocal] = useState({
        id: Date.now(),
        title: "",
        date: dayjs().format("YYYY"),
    });

    useEffect(() => {
        if (formation) setFormationLocal(formation);
    }, [formation]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formationLocal, formation ? true : false);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    return (
        <form onSubmit={handleSubmit}>
            <Card className="flex flex-col items-center justify-between gap-5">
                <div className="flex lg:flex-col flex-row gap-10 w-full">
                    <div className="w-full lg:w-1/3">
                        <p className="text-blue-dark cera-font text-sm">Année</p>
                        <div className="flex items-center gap-2">

                            <Select
                                onChange={(e) => {
                                    const date = dayjs(e.target.value).format("YYYY-MM-DD");
                                    setFormationLocal({ ...formationLocal, date })
                                }}
                                required={true}
                                value={dayjs(formationLocal.date).format("YYYY")}
                            >
                                {[...Array(100)].map((_, i) => (
                                    <option value={dayjs().subtract(i, "year").format("YYYY")}>
                                        {dayjs().subtract(i, "year").format("YYYY")}
                                    </option>
                                ))}
                            </Select>

                        </div>
                    </div>
                </div>
                <div className="flex justify-start w-full">
                    <Textarea
                        onChange={(e) => setFormationLocal({ ...formationLocal, title: e.target.value })}
                        value={formationLocal.title}
                        name="title"
                        placeholder="Titre de la formation"
                        title="Description"
                        required={true}
                    ></Textarea>
                </div>
                <div className="flex w-full gap-5">
                    <SmallRoundedButton className="border-gray-light text-gray-light" onClick={handleCancel}>
                        Annuler
                    </SmallRoundedButton>
                    <SmallRoundedButton isFill type="submit">
                        {formation ? "Modifier" : "Ajouter"}
                    </SmallRoundedButton>
                </div>
            </Card>
        </form>
    );
};
