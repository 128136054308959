export default function searchInTalents(talent, search) {
    /**
     * Search in:
     * -> firstname
     * -> lastname
     * -> firstname + lastname
     * -> job
     * -> entity name
     */
    if (search) {
        return talent.User.firstname.toLowerCase().includes(search.toLowerCase()) || talent.User.lastname.toLowerCase().includes(search.toLowerCase()) || `${talent.User.firstname} ${talent.User.lastname}`.toLowerCase().includes(search.toLowerCase()) ||
            talent.job.toLowerCase().includes(search.toLowerCase()) || talent.Entity.title.toLowerCase().includes(search.toLowerCase());
    } else {
        return true;
    }
}